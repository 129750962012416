import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material';
import {v} from '@angular/core/src/render3';

@Component({
  selector: 'app-visitant-detail',
  templateUrl: './visitant-detail.component.html',
  styleUrls: ['./visitant-detail.component.scss']
})
export class VisitorDetailComponent implements OnInit {

  malls;
  visitantData;
  userSign = '';
  showSigner = false;
  stopRewrite = false;

  //Tabla Vars
  ELEMENT_DATA = [];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
  displayedColumns = ['eventName', 'eventStart', 'mallId', 'agreeImage', 'agreeExoneration', 'userSign'];

  padOptions = {
    canvasWidth: '300',
    canvasHeight: '400',
    penColor: '#333',
    backgroundColor: '#fff'
  };

  timer = timer(500);
  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.getMalls();
    this.route.params.pipe().subscribe( ({DNI: dni}) => {
      dni;
      this.apiService.getVisitorDetail(dni).subscribe( (response: any) => {
        console.log('data localhost,' , response);
        if (response) {
          this.visitantData = response.data;
          this.dataSource = new MatTableDataSource<any>(response.data.detail);
        }
      } );
    });
  }

  ngOnInit(): void { }

  retDate(birthday) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    console.log('birthday', birthday);
    const d = new Date(birthday);
    return d.toLocaleDateString('es-ES', options);
  }

  getMalls() {
    this.apiService.getListMall().subscribe(response => {
      this.malls = response;
    });
  }

  findMall(malls, id) {
    if (malls) {
      return (malls.filter(mall => mall.CodInmueble === id)[0] || {Inmueble: ''}).Inmueble;
    }
    return null;
  }

  goDraw(signer) {
    if (!this.stopRewrite) {
      // console.log('this.userSign', this.userSign.length, signer)
      signer.signaturePad.fromDataURL(this.userSign);
      signer.signaturePad.off();
      this.stopRewrite = true;
    }
    return null;
  }

  openSigner(modal, userSign) {
    this.userSign = userSign;
    this.stopRewrite = false;
    const modalRef = this.modalService.open(modal);
    modalRef.result.then( () => {
      this.showSigner = false;
      this.stopRewrite = false;
    } ).catch((e) => console.log(e));
    setTimeout(() => {
      this.showSigner = true;
    }, 300);
  }

  drawBegin() {
  }

  drawComplete(signerPadCheck) {
  }
}
