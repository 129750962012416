import {Component, OnInit, ViewChild} from '@angular/core';
import {MessagesService} from '../../../../services/messages.service';
import {ApiService} from '../../../../services/api.service';
import {Select} from '../../../../models/select';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {default as _rollupMoment, Moment} from 'moment';
import {HttpErrorResponse} from '@angular/common/http';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl, FormGroup, Validators,FormBuilder,NgForm} from '@angular/forms';
import {MatPaginator, MatTableDataSource, MatSort,MatSelect} from '@angular/material';
import {EventAssing} from '../../../../models/responseEventAssing';
import {ListEventMall} from '../../../../models/listEventMall';
import {SpecifyDate} from '../../../../models/specifyDate';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { AssigEventMallComponent } from '../mall/assig-event-mall/assig-event-mall.component'
import { BsModalService } from 'ngx-bootstrap/modal';
function validateDate(c: FormControl) {

  let isValid = null;
  if (c.value == null || c.value == undefined  || c.value == '' || c.value.lenght<1)   {
    isValid = true;
  }
  else{
    if (!moment(c.value, 'DD-MM-YYYY').isValid()) {
      isValid = null;
    } else {
      isValid = true;
      const fechaInitMoment = moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
      const FechaCompare = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (fechaInitMoment >= FechaCompare) {
        isValid = null;
      } else {
        isValid = true;
      }
    }
  }
  return isValid ? null : {
    validateDate: {
      valid: false
    }
  };

}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-maintenance-admin-mall',
  templateUrl: './maintenance-admin-mall.component.html',
  styleUrls: ['./maintenance-admin-mall.component.styl'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})


export class MaintenanceAdminComponentMall implements OnInit {
  p_event_name: string;
  bsModalRef: BsModalRef;
  stateSpecifyDate: boolean;
  p_mall_id: number;
  p_event_mall_id : number;
  mask = {
    guide: false,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };
  list_event: ListEventMall[];  
  data_user: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  modalReference: any;
  event_id_select: FormControl;
  secondCtrl: FormControl;
  id_event: FormControl;
  with_exoneration: FormControl;
  with_image: FormControl;
  date_end: FormControl;
  date_start: FormControl;
  id_event_update: FormControl;
  event_name_update : FormControl;
  with_exoneration_update: FormControl;
  with_image_update: FormControl;
  date_end_update: FormControl;
  date_start_update: FormControl;
  modelForm: EventAssing = new EventAssing();
  selectForm: FormGroup;
  eventForm: FormGroup;
  especifyDate: FormGroup;
  eventFormUpdate: FormGroup;
  options_mall: Select[];
  options_event: Select[];
  displayedColumnsHome: string[] = ['position', 'name'];

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  expandedElement: PeriodicElement | null;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  private banks: Select[] = [{value: "-1", name : "Escriba para buscar eventos" }]
  private _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);


  @ViewChild('singleSelect') singleSelect: MatSelect; 
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
   

  info_route = {
    breadcrumb_level: 2,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Maintenance',
    breadcrumb_subtitle: 'Mantenimiento'
  };

  constructor(
    private messagesService: MessagesService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private bsModalService: BsModalService,
    private _formBuilder: FormBuilder) {
  }

  onClickReasignarEvent() {
    if (!this.eventFormUpdate.valid) {
      this.validateForm(this.eventFormUpdate);
      return;
    }

    this.modelForm.id_mall = this.p_mall_id;
    this.modelForm.id_event = this.selectForm.value.event_id_select.value;
    this.modelForm.date_start = this.eventFormUpdate.value.date_start_update;
    this.modelForm.date_end = this.eventFormUpdate.value.date_end_update;
    this.modelForm.with_exoneration = this.eventFormUpdate.value.with_exoneration_update;
    this.modelForm.with_image = this.eventFormUpdate.value.with_image_update;
    this.modelForm.date_start = moment(this.modelForm.date_start, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.modelForm.date_end = moment(this.modelForm.date_end, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.modelForm.lunes = this.eventFormUpdate.value.lunes_update;
    this.modelForm.martes = this.eventFormUpdate.value.martes_update;
    this.modelForm.miercoles = this.eventFormUpdate.value.miercoles_update;
    this.modelForm.jueves = this.eventFormUpdate.value.jueves_update;
    this.modelForm.viernes = this.eventFormUpdate.value.viernes_update;
    this.modelForm.sabado = this.eventFormUpdate.value.sabado_update;
    this.modelForm.domingo = this.eventFormUpdate.value.domingo_update;
    this.apiService.putEventAssing(this.modelForm).subscribe((response) => {
        this.onSelectedEvent();
        this.modalReference.close();
        this.messagesService.success('Se realizo la modificación con exito.');
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }
  openSpecifi(content, element: any) {
    this.modalReference = this.modalService.open(content, { windowClass: 'my-class',centered: true, backdrop: 'static'});
  }
  openLgUpdate(content, element: any) {

    if (element.with_exoneration=="verified_user") {
      this.eventFormUpdate.controls['with_exoneration_update'].reset({
        value:true,
        disabled: false
      });
    }
    else{
      this.eventFormUpdate.controls['with_exoneration_update'].reset({
        value:false,
        disabled: false
      });
    }
    if (element.with_image=="verified_user") {
      this.eventFormUpdate.controls['with_image_update'].reset({
        value:true,
        disabled: false
      });
    }
    else{
      this.eventFormUpdate.controls['with_image_update'].reset({
        value:false,
        disabled: false
      });
    }

    
    this.eventFormUpdate.controls['event_name_update'].reset({value: this.p_event_name, disabled: true});
    this.eventFormUpdate.controls['date_start_update'].reset({value: element.date_start, disabled: false});
    this.eventFormUpdate.controls['date_end_update'].reset({value: element.date_end, disabled: false});
    
    this.eventFormUpdate.controls['lunes_update'].reset({value: element.lunes, disabled: false});
    this.eventFormUpdate.controls['martes_update'].reset({value: element.martes, disabled: false});
    this.eventFormUpdate.controls['miercoles_update'].reset({value: element.miercoles, disabled: false});
    this.eventFormUpdate.controls['jueves_update'].reset({value: element.jueves, disabled: false});
    this.eventFormUpdate.controls['viernes_update'].reset({value: element.viernes, disabled: false});
    this.eventFormUpdate.controls['sabado_update'].reset({value: element.sabado, disabled: false});
    this.eventFormUpdate.controls['domingo_update'].reset({value: element.domingo, disabled: false});
   
    this.p_mall_id = element.id;
    console.log(this.p_mall_id);
    this.modalReference = this.modalService.open(content, {windowClass: 'modal-tm', centered: true, backdrop: 'static', size: 'lg'});

  }

  onSelectedEvent() {
    this.p_event_name = this.selectForm.value.event_id_select.name;
    this.apiService.getListarMallEvent(this.selectForm.value.event_id_select.value).subscribe((response) => {
        const cadena = response.data;
        this.list_event = [];
        let position = 0;
        let id_mall=-1;
        let name_mall;
        let list;
        const final = cadena.length;
        let contador = 0;
        cadena.forEach(element => {
          contador = contador +1;
          if (id_mall==-1) {
            list = new ListEventMall();
            list.specifyDate = [];
            id_mall = element.mall_id;
            name_mall = element.name_mall;
          }
          if (id_mall == element.mall_id) {
            const dateRow = new SpecifyDate();
            dateRow.id = element.id
            if (element.date_start === null || element.date_end === null ) {
              dateRow.color = 'warn';
              dateRow.colorRow = '#C9D8DA';
              dateRow.date_start = element.date_start;
              dateRow.date_end =element.date_end;
              dateRow.ico_state= 'add_alert';
              dateRow.ico_state_msg = 'Asignación pendiente de fecha'
            }
            else{
              dateRow.date_start = moment(element.date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
              dateRow.date_end = moment(element.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            dateRow.with_exoneration_boolean = element.with_exoneration === '1' ? true : false;
            if (element.with_exoneration) {
              dateRow.with_exoneration = 'verified_user';
              dateRow.with_exoneration_msg = 'Con exoneración de responsabilidades';
            } else {
              dateRow.with_exoneration = 'report';
              dateRow.with_exoneration_msg = 'Sin exoneración de responsabilidades';
            }
            dateRow.with_image_boolean = element.with_image === '1' ? true : false;
            if (element.with_image) {
              dateRow.with_image = 'verified_user';
              dateRow.with_image_msg = 'Con consentimiento de uso de imagen';
            } else {
              dateRow.with_image = 'report';
              dateRow.with_image_msg = 'Sin consentimiento de uso de imagen';
            }

            dateRow.lunes= element.lunes=="true" ? true : false;
            dateRow.martes= element.martes=="true" ? true : false;
            dateRow.miercoles= element.miercoles=="true" ? true : false;
            dateRow.jueves= element.jueves=="true" ? true : false;
            dateRow.viernes= element.viernes=="true" ? true : false;
            dateRow.sabado= element.sabado=="true" ? true : false;
            dateRow.domingo= element.domingo=="true" ? true : false;

            list.specifyDate.push(dateRow);
            if (final<=contador) {
              position = position + 1;
              list.position = position;
              list.id = id_mall;
              list.name = name_mall;
              this.list_event.push(list);

            }
          }
          else{
            position = position + 1;
            list.position = position;
            list.id = id_mall;
            list.name = name_mall;
            this.list_event.push(list);

            list = new ListEventMall();
            list.specifyDate = [];
            id_mall = element.mall_id;
            name_mall = element.name_mall;
            list.id = id_mall;
            list.name = name_mall;
            //Men
            const dateRow = new SpecifyDate();
            dateRow.id = element.id
            if (element.date_start === null || element.date_end === null ) {
              dateRow.color = 'warn';
              dateRow.colorRow = '#C9D8DA';
              dateRow.date_start = element.date_start;
              dateRow.date_end =element.date_end;
              dateRow.ico_state= 'add_alert';
              dateRow.ico_state_msg = 'Asignación pendiente de fecha'
            }
            else{
              dateRow.date_start = moment(element.date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
              dateRow.date_end = moment(element.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            dateRow.with_exoneration_boolean = element.with_exoneration === '1' ? true : false;
            if (element.with_exoneration) {
              dateRow.with_exoneration = 'verified_user';
              dateRow.with_exoneration_msg = 'Con exoneración de responsabilidades';
            } else {
              dateRow.with_exoneration = 'report';
              dateRow.with_exoneration_msg = 'Sin exoneración de responsabilidades';
            }

            dateRow.with_image_boolean = element.with_image === '1' ? true : false;
            if (element.with_image) {
              dateRow.with_image = 'verified_user';
              dateRow.with_image_msg = 'Con consentimiento de uso de imagen';
            } else {
              dateRow.with_image = 'report';
              dateRow.with_image_msg = 'Sin consentimiento de uso de imagen';
            }

            dateRow.lunes= element.lunes=="true" ? true : false;
            dateRow.martes= element.martes=="true" ? true : false;
            dateRow.miercoles= element.miercoles=="true" ? true : false;
            dateRow.jueves= element.jueves=="true" ? true : false;
            dateRow.viernes= element.viernes=="true" ? true : false;
            dateRow.sabado= element.sabado=="true" ? true : false;
            dateRow.domingo= element.domingo=="true" ? true : false;
            
            list.specifyDate.push(dateRow);
            if (final<=contador) {
              position = position + 1;
              list.position = position;
              this.list_event.push(list);
            }
          }

        });

        ELEMENT_DATA = this.list_event;
        this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al obtener los datos, por favor actualize la pagina.');
      });
  }

  openDelete(content, element: any) {
    this.p_event_mall_id = element.id;
    this.modalReference = this.modalService.open(content, {windowClass: 'modal-delete',centered: true, size: 'sm', backdrop: 'static'});
    this.modalReference.result.then((result) => {
    }, (reason) => {
    });
  }

  getMall() {
    this.options_mall = [];
    this.apiService.getListMall().subscribe(response => {
      let options: any = response.filter(mall => {
        const exclude_malls = [4, 8, 73, 77, 76, 78];
        const mall_id = mall.CodInmueble;
        if (!exclude_malls.includes(mall_id)) {
          return mall;
        }
      });

      options = options.sort(function (a, b) {
        if (a.Inmueble > b.Inmueble) {
          return 1;
        }
        if (a.Inmueble < b.Inmueble) {
          return -1;
        }
        return 0;
      });
      for (const option of options) {
        const select = new Select();
        select.name = option.Inmueble;
        select.value = option.CodInmueble;
        this.options_mall.push(select);
      }
    });
  }

 

  onClickDeleteAssing() {

    this.apiService.deleteAssingEvent(this.p_event_mall_id, this.selectForm.value.event_id_select.value).subscribe((response) => {
        this.onSelectedEvent();
        this.modalReference.close();
        this.messagesService.success('El evento fue eliminado con exito.');
      },
      (errorResponse: HttpErrorResponse) => {
        this.modalReference.close();
        this.messagesService.error('Ocurrió un error al eliminar el evento, por favor intente de nuevo.');
      });
  }

  getEvents() {
    this.apiService.getListEventNoAssigned(2).subscribe(response => {
      this.banks = [];
      const options: any = response.data;
      for (const option of options) {
        const select = new Select();
        select.name = option.name;
        select.value = option.id;
        this.banks.push(select);
      }
    });

   
  }
  

  
  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  createFormControls() {
    this.id_event = new FormControl(null, [
      Validators.required
    ]);

    this.date_start_update = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate,
      
    ]);
    this.date_end_update = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.event_name_update = new FormControl(null)
    this.with_exoneration_update = new FormControl(null, [
      Validators.required
    ]);
    this.with_image_update = new FormControl(null, [
      Validators.required
    ]);
    this.date_start = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.date_end = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.with_exoneration = new FormControl(false, [ ]);
    this.with_image = new FormControl(false, [ ]);
    this.secondCtrl = new FormControl(null, [  Validators.required,]);
    this.event_id_select = new FormControl(null, [  Validators.required,]);
  }

  createForm() {
    this.selectForm = new FormGroup({
      event_id_select : this.event_id_select
    })
    this.eventFormUpdate = new FormGroup({
      date_start_update: this.date_start_update,
      date_end_update: this.date_end_update,
      event_name_update: this.event_name_update,
      with_exoneration_update: this.with_exoneration_update,
      with_image_update: this.with_image_update,
      lunes_update : new FormControl(null, []),
      martes_update : new FormControl(null, []),
      miercoles_update : new FormControl(null, []),
      jueves_update : new FormControl(null, []),
      viernes_update : new FormControl(null, []),
      sabado_update : new FormControl(null, []),
      domingo_update : new FormControl(null, []),
    });

  }


  configInit() {
    this.data_user = ApiService.getDataUser();
    this.data_user.mallId = 0;
    this.stateSpecifyDate = false;
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.configInit();
    this.getEvents();
    this.getMall();
   
    ELEMENT_DATA = [];
    this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // load the initial bank list
    this.filteredBanks.next(this.banks.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {          
        this.singleSelect.compareWith = (a: Select, b: Select) => a.value === b.value;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openModalWithComponent() {
    const initialState = {
      title: 'Asignar evento a malls'
    };
    this.bsModalRef = this.bsModalService.show(AssigEventMallComponent,{class: 'modal-lg',initialState,backdrop: 'static'});
    this.bsModalRef.content.closeBtnName = 'Close';


    (<AssigEventMallComponent>this.bsModalRef.content).onClose.subscribe(result => {
       
        if (result === true) {
          this.onSelectedEvent();
        } else if(result === false) {
            // when pressed No
        } else {
            // When closing the modal without no or yes
        }
    });
  }


  private filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


}


export interface PeriodicElement {
  position : any;
  name: string;
  typeString: string;
  typeSimbol: string;
  specifyDate: SpecifyDate[];
  id: number
}



let ELEMENT_DATA: PeriodicElement[] = [];
const moment = _rollupMoment || _moment;


