import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import {MessagesService} from '../../../../services/messages.service';
import {ApiService} from '../../../../services/api.service';
import {Select} from '../../../../models/select';
import {ListEvent} from '../../../../models/listEvent';
import {HttpErrorResponse} from '@angular/common/http';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatPaginator, MatTableDataSource, MatSort} from '@angular/material';
import {MatDialog} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {Evento} from '../../../../models/responseEvento';

export interface DialogData {
  animal: string;
  name: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance-admin-event.component.html',
  styleUrls: ['./maintenance-admin-event.component.styl'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the compoimport { map } from 'rxjs/operators';
// nent level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ],
})
export class MaintenanceAdminComponentEvent implements OnInit {
  isDisabled: boolean;
  data_user: any;
  id_event: number;
  list_event: ListEvent[];
  modelForm: Evento = new Evento();
  event_type: FormControl;
  event_type_id: number;
  name_event: FormControl;
  eventForm: FormGroup;
  event_type_update: FormControl;
  name_event_update: FormControl;
  eventFormUpdate: FormGroup;
  options_tipo: Select[];
  animal: string;
  name1: string;
  openModal: NgbActiveModal;
  displayedColumns: string[] = ['position', 'name', 'id'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  info_route = {
    breadcrumb_level: 2,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Maintenance',
    breadcrumb_subtitle: 'Mantenimiento de eventos'
  };

  constructor(
    public dialog: MatDialog,
    private messagesService: MessagesService,
    private apiService: ApiService,
    private modalService: NgbModal) {
  }

  openDelete(content, id: number) {
    this.openModal = this.modalService.open(content, {centered: true});
    this.id_event = id;
  }

  openUpdate(content, id: number, name: string) {
    this.openModal = this.modalService.open(content, {centered: true});
    this.id_event = id;
    this.eventFormUpdate.controls['name_event_update'].reset({value: name, disabled: false});
  }



  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }


  clearForm() {
    this.modelForm = new Evento();
    this.eventForm.controls['name_event'].reset({value: null, disabled: false});

  }

  createFormControls() {
    this.name_event = new FormControl(null, [
      Validators.required
    ]);
    this.event_type = new FormControl(null, []);
    this.name_event_update = new FormControl(null, []);
    this.event_type_update = new FormControl(null, []);
  }

  createForm() {
    this.eventForm = new FormGroup({
      name_event: this.name_event,
      event_type: this.event_type,
    });
    this.eventFormUpdate = new FormGroup({
      name_event_update: this.name_event_update,
      event_type_update: this.event_type_update
    });
  }


  onClickCreateEvent() {
    if (!this.eventForm.valid) {
      this.validateForm(this.eventForm);
      return;
    }
    this.isDisabled = true;
    this.modelForm = this.eventForm.value;
    this.modelForm.event_type_id = this.event_type_id;
    this.modelForm.id_mall = this.data_user.mallId;
    this.apiService.postEventInsert(this.modelForm).subscribe((response) => {
        this.listarEventos();
        this.clearForm();
        this.messagesService.success('Evento creado con exito.');
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }

  onClickUpdateEvent() {
    this.modelForm.name_event = this.eventFormUpdate.value.name_event_update;
    this.modelForm.event_type_id = this.event_type_id;
    this.apiService.updateEvent(this.id_event, this.modelForm).subscribe((response) => {
        this.messagesService.success('El evento fue modificado con exito.');
        this.openModal.close();
        this.listarEventos();
      },
      (errorResponse: HttpErrorResponse) => {
        this.openModal.close();
        this.messagesService.error('Ocurrió un error al modificar el evento, por favor intente de nuevo.');
      });
  }

  onClickDeleteEvent() {
    this.apiService.deleteEvent(this.id_event).subscribe((response) => {
        this.messagesService.success('El evento fue eliminado con exito.');
        this.openModal.close();
        this.listarEventos();
      },
      (errorResponse: HttpErrorResponse) => {
        this.openModal.close();
        this.messagesService.error('Ocurrió un error al eliminar el evento, por favor intente de nuevo.');
      });
  }

  listarEventos() {
    this.apiService.getListarEventMall(this.data_user.mallId, this.event_type_id).subscribe((response) => {
        const cadena = response.data;
        this.list_event = [];
        let position = 0;
        let ctl = -1
        cadena.forEach(element => {
          if (element.type == "Mall" ||  this.event_type_id == 2) {
            const list = new ListEvent();
            list.name = element.name;
            list.id = element.id;
            if (ctl== -1) {
              position = position + 1;
              list.position = position;
              ctl =  element.id;
              this.list_event.push(list);
            }
            if (ctl == element.id) {
            }
            else{
              position = position + 1;
              list.position = position;
              ctl =  element.id;
              this.list_event.push(list);
            }
          }
        });
        ELEMENT_DATA = this.list_event;
        this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al obtener los datos, por favor actualize la pagina.');
      });

  }

  configInit() {
    this.data_user = ApiService.getDataUser();

      this.eventForm.controls['event_type'].reset({value: 'Transversal', disabled: true});
      this.eventFormUpdate.controls['event_type_update'].reset({value: 'Transversal', disabled: true});
      this.event_type_id = 2;
      this.data_user.mallId= 0;
     
      this.listarEventos();

  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  ngOnInit() {
    this.createFormControls();
    this.createForm();

    this.configInit();

  }


}

export interface PeriodicElement {
  name: string;
  position: number;
  id: number;
}

let ELEMENT_DATA: PeriodicElement[];

