import {Component, OnInit, ViewChild, TemplateRef, EventEmitter, Output} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ApiService} from './../../services/api.service';
import {MessagesService} from './../../services/messages.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ResponseItem, ResponseMenuChild} from './../../models/responseMenu';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import {environment} from './../../../environments/environment';
import {NgbModal, NgbModalOptions, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.styl']
})
export class PrincipalComponent implements OnInit {


  showStyle: boolean = false;
//  menu: ResponseItem[];
  menu = [];
  data_user: any;

  @ViewChild('modalSesion')
  private modal: TemplateRef<any>;

  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true
  };

  modalReference: NgbModalRef;

  closeResult: string;
  modal_message: string = environment.inactivity_message;
  lastPing?: Date = null;
  force_logout = false;
  modal_open = false;

  constructor(private idle: Idle, private keepalive: Keepalive, private modalService: NgbModal, private authService: AuthService, private apiService: ApiService, private messagesService: MessagesService) {
    idle.setIdle(environment.inactivity_first_time);
    idle.setTimeout(environment.inactivity_first_time);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      this.modalReference.close();
      if (!this.force_logout) {
        this.authService.logout();
      }
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      console.log(countdown);
      if (countdown === environment.inactivity_first_time && !this.force_logout && !this.modal_open) {
        this.open();
      }
    });

    keepalive.interval(15);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();
  }

  ngOnInit() {
    this.data_user = ApiService.getDataUser();
    this.apiService.getListMenu().subscribe((options) => {

        // const response = {
        //   "options": {
        //     "menu": [
        //       {
        //         "codeProfile": "105",
        //         "nameProfile": "Visitantes - Admin",
        //         "label": "Registro de datos",
        //         "icon": "bubble_chart",
        //         "child": [{
        //           "label": "Visitantes",
        //           "route": "/users/register",
        //           "flatModal": "0"
        //         },{
        //           "label": "Ecoven",
        //           "route": "/users/specials",
        //           "flatModal": "0"
        //         }]
        //       },
        //       {
        //         "codeProfile": "105",
        //         "nameProfile": "Visitantes - Admin",
        //         "label": "Administración Mall",
        //         "icon": "bubble_chart",
        //         "child": [
        //           {
        //             "label": "Creación de Evento",
        //             "route": "/events/mall",
        //             "flatModal": "0"
        //           },
        //           {
        //             "label": "Configuración de Evento",
        //             "route": "/assign/event-mall",
        //             "flatModal": "0"
        //           }
        //         ]
        //       },
        //       {
        //         "codeProfile": "105",
        //         "nameProfile": "Visitantes - Admin",
        //         "label": "Administración Admin",
        //         "icon": "bubble_chart",
        //         "child": [
        //           {
        //             "label": "Creación de Evento",
        //             "route": "/events/admin",
        //             "flatModal": "0"
        //           },
        //           {
        //             "label": "Configuración de Evento",
        //             "route": "/assign/event-admin",
        //             "flatModal": "0"
        //           }
        //         ]
        //       },
        //       {
        //         "codeProfile": "105",
        //         "nameProfile": "Visitantes - Admin",
        //         "label": "Panel de Control",
        //         "icon": "dashboard",
        //         "route": "/dashboard"
        //       }
        //     ]
        //   }
        // };
        // this.menu = response.options.menu;

  //      if (this.data_user.mallId == 0) {

    //      this.menu = [options.options.menu[2]];
     //   } else {
          this.menu = options.options.menu;
      //  }
      },
      (err: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error inesperado con nuestro proveedor');
      }
    );


  }

  toggleSideBar() {
    this.showStyle = !this.showStyle;
  }

  logout() {
    this.authService.logout();
  }

  reset() {
    this.idle.watch();
  }

  open() {
    this.modal_open = false;
    this.force_logout = true;
    this.forceLogout();
  }

  forceLogout() {
    this.authService.logoutRedirect();
  }


}
