// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    url_menu: 'https://msdev.advancerealestate.com.pe/v1/users/profiles/menu',
    token_url_menu: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',
    code_system: 15,

    password_encryp: 'd6F3Efeq',
    url_unsubscribe: 'https://unsubscribe.devrealplazaonline.com/unsubscribe/',

    email_sender: 'test-ingsoftware@realplaza.com.pe',

    url_list_malls: 'https://ms.advancerealestate.com.pe/v1/inmuebles',
    token_url_list_malls: 'NNddqlnqS85cD6aTFjUv7ckgpqIC39N6L2uMrJN3',

    url_list_event: 'https://api.devrealplazaonline.com/v1/dev/external-sources/real-estate/',
    token_url_list_event: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_event_type: 'https://api.devrealplazaonline.com/v1/dev/external-sources/event-types',
    token_url_event_type: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_list_special_event: 'https://api.devrealplazaonline.com/v1/dev/external-sources/real-estate/',
    token_url_list_special_event: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_list_gender: 'https://api.devrealplazaonline.com/v1/dev/external-sources/genders',
    token_url_list_gender: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_list_document_type: 'https://api.realplazalabs.com/v1/visitors/reference-values/search?type=document_type',
    token_url_document_type: 'gNCXe0Ug8o71GwMaQqSTQ15m5qq6wK7L6tjM10W5',

    url_list_department: 'https://api.realplazalabs.com/v1/visitors/ubigeo/departaments',
    token_url_list_department: 'gNCXe0Ug8o71GwMaQqSTQ15m5qq6wK7L6tjM10W5',

    url_list_province: 'https://api.realplazalabs.com/v1/visitors/ubigeo/departaments/{departmentId}/provinces',
    token_url_list_province: 'gNCXe0Ug8o71GwMaQqSTQ15m5qq6wK7L6tjM10W5',

    url_list_district: 'https://api.realplazalabs.com/v1/visitors/ubigeo/departaments/1/provinces/{provinceId}/districts',
    token_url_list_district: 'gNCXe0Ug8o71GwMaQqSTQ15m5qq6wK7L6tjM10W5',

    url_list_exchange: 'https://api.devrealplazaonline.com/v1/dev/external-sources/exchanges',
    token_url_list_exchange: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_visitor_search_by_bni: 'https://api.devrealplazaonline.com/v1/dev/external-sources/visitors',
    token_url_visitor_search_by_bni: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_visitor_search: 'https://api.realplazalabs.com/v1/visitors/search',
    //url_visitor_search: 'http://localhost:8088/visitors/visitors/search',

    url_visitor_detail: 'https://api.realplazalabs.com/v1/visitors/visitors',
    //url_visitor_detail: 'http://localhost:8088/visitors/visitors/visitor',

    url_visitor_export: 'https://api.realplazalabs.com/v1/visitors/export',
    //url_visitor_export: 'http://localhost:8088/visitors/visitors/export',

    url_visitor_search_by_bni_externo: 'https://api.devrealplazaonline.com/v1/dev/external-sources/reniec',

    url_visitor_insert: 'https://api.realplazalabs.com/v1/visitors/visitors',
    token_url_visitor_insert: 'gNCXe0Ug8o71GwMaQqSTQ15m5qq6wK7L6tjM10W5',

    url_visitor_update: 'https://api.devrealplazaonline.com/v1/dev/external-sources/visitors',
    token_url_visitor_update: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_email_send: 'https://api.devrealplazaonline.com/v1/dev/common/emails',
    token_url_email_send: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_list_tradename: 'https://msdev.advancerealestate.com.pe/v1/tenants/contracts/tradenames',
    token_url_list_tradename: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',

    url_event_assing_update: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_event_assing_update: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_event_validate_code: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_event_validate_code: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_event_get_data_structure: 'https://api.realplazalabs.com/v1/visitors/data/data-structure/',
    token_url_get_data_structure: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_evento_insert: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events',
    token_url_evento_insert: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_evento_mall_list: 'https://api.devrealplazaonline.com/v1/dev/external-sources/real-estate/',
    token_url_evento_mall_list: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_mall_evento_list: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_mall_evento_list: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_evento_list: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events',
    token_url_evento_list: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_event_assing_insert: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_event_assing_insert: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_evento_update: 'https://api..com/vdevrealplazaonline1/dev/external-sources/events/',
    token_url_evento_update: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_event_assing_delete: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_event_assing_delete: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_evento_delete: 'https://api.devrealplazaonline.com/v1/dev/external-sources/events/',
    token_url_evento_delete: '0I488blpaB1zfu43CYne1qbfv4k5bo11EugDhzj6',

    url_contract_user: 'https://msdev.advancerealestate.com.pe/v1/users/contracts',
    token_url_contract_user: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',

    url_usuario_sistema: 'https://msdev.advancerealestate.com.pe/v1/users/profiles',
    token_url_usuario_sistema: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',

    url_acceso_directo: 'https://msdev.advancerealestate.com.pe/v1/users/direct-access',
    token_url_acceso_directo: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',
  },

  auth_credentials: {
    url: 'https://api.realplazalabs.com/v1/identity',
    client_id: 'realplaza.visitors',
    client_secret: '400584D5-FC6A-4829-B4F6-AB2FC5FACFFD',
    grant_type: 'password',
    scope: 'ms-rp-visitors offline_access'
  },

  refresh_credentials: {
    url: 'https://api.realplazalabs.com/v1/identity',
    client_id: 'realplaza.visitors',
    client_secret: '400584D5-FC6A-4829-B4F6-AB2FC5FACFFD',
    grant_type: 'refresh_token',
    scope: 'ms-rp-visitors offline_access'
  },

  profile: {
    url: 'https://msdev.advancerealestate.com.pe/v1/users/profiles',
    token: 'TI4kPiQyT313oG8knnPXd6mzx29FQmBCa01gaATr',
    code_system: 15,
  },

  inactivity_first_time: 1440 * 60, // 10 min
  inactivity_second_time: 1, // 2 min
  inactivity_message: 'Se termino la sesión por favor identifiquese.'
};
