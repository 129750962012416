import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.addAuthenticationToken(req);
    //return next.handle(req)
    return next.handle(req);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    let parameters = new URLSearchParams(request.url);
    let dataUser = this.auth.getDataUser();
    if (!dataUser || !dataUser.access_token || parameters.get('X-Amz-Expires')) {
      return request;
    }
    return request.clone({
      setHeaders: {Authorization: `${dataUser.token_type} ${dataUser.access_token}`}
    });
  }
}
