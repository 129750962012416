import { Component, OnInit, NgModule, ChangeDetectorRef } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { ApiService } from '../../services/api.service';
import { Select } from '../../models/select';
import { SelectEvent } from '../../models/selectEvent';
import { HttpErrorResponse } from '@angular/common/http';
import { Visitor } from '../../models/responseVisitor';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'ngx-custom-validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectUbigeo } from 'src/app/models/selectUbigeo';
import { countryData } from './../../models/ContryData';
import { ReplaySubject, Subject, of } from 'rxjs';


const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-special-form',
  templateUrl: './special-form.component.html',
  styleUrls: ['./special-form.component.styl'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],
})

@NgModule({
  imports: [BrowserModule,
    NgbModule.forRoot()],
  declarations: [SpecialFormComponent],
  bootstrap: [SpecialFormComponent]
})

export class SpecialFormComponent implements OnInit {
  p_variable_validator: boolean;
  p_exchange: string;
  p_number_points: string;
  values: string = '';
  mask = {
    guide: false,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };
  p_agree_terms: boolean;
  visilidad_formulario: boolean;
  visilidad_spinner: boolean;
  data_user: any;
  exonerador: boolean;
  imager: boolean;
  visitorForm: FormGroup;
  mall_id: FormControl;
  event_id: FormControl;
  document: FormControl;
  gender: FormControl;
  firstName: FormControl;
  last_name: FormControl;
  birthdate: FormControl;
  email: FormControl;
  cellPhone: FormControl;
  document_type: FormControl;
  nationality: FormControl;
  department: FormControl;
  province: FormControl;
  district: FormControl;
  agree_terms: FormControl;
  agree_exonerado: FormControl;
  agree_image: FormControl;
  maxFecha: Date = new Date();

  dynamicalGroup: FormGroup;

  info_route = {
    breadcrumb_level: 2,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Visitor',
    breadcrumb_subtitle: 'Register'
  };

  options_gender: Select[];
  options_event: Select[];
  options_exchange: Select[];
  options_document_type: Select[];
  options_country: any = [];
  options_department: SelectUbigeo[];
  options_province: SelectUbigeo[] = [];
  options_district: SelectUbigeo[] = [];
  isDisabled = true;
  isNewVisitor = true;

  comboBoxExample = [];
  jsonOfForm = [];

  modelForm: Visitor = new Visitor();

  modelFormErrors: any = {};

  openModal: NgbActiveModal;
  padOptions = {
    canvasWidth: '300',
    canvasHeight: '400',
    penColor: '#333',
    backgroundColor: '#fff'
  };
  _signature: any = null;
  signCheck = false;

  visibleJsonTest = false;

  events_error = false;

  constructor(
    private messagesService: MessagesService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private changeDedectionRef: ChangeDetectorRef
  ) {
    this.p_variable_validator = false;
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getEvents();
    this.getGender();
    this.getExchange();
    this.getCountry();
    this.getDepartment();
    this.getDocumentType();
    this.visilidad_formulario = false;
    this.visilidad_spinner = false;
  }

  validateDate(c: FormControl) {
    let isValid = null;
    if (c.value == null || c.value == undefined || c.value == '') {
      isValid = true;
    } else {
      if (!moment(c.value, 'DD-MM-YYYY').isValid()) {
        isValid = null;
      } else {
        isValid = true;
        const fechaInitMoment = moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
        const FechaCompare = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
        if (fechaInitMoment >= FechaCompare) {
          isValid = null;
        } else {
          isValid = true;
        }
      }
    }
    return isValid ? null : {
      validateDate: {
        valid: false
      }
    };
  }


  createFormControls() {
    this.mall_id = new FormControl(null);
    this.event_id = new FormControl(null, Validators.required);
    this.document = new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(12),
      Validators.pattern('^[a-zA-Z0-9]+$')
    ]);
    this.firstName = new FormControl({ value: null, disabled: true }, Validators.required);
    this.last_name = new FormControl({ value: null, disabled: true }, Validators.required);
    this.birthdate = new FormControl({ value: null, disabled: true },
      [
        Validators.minLength(10),
        this.validateDate
      ]);
    this.gender = new FormControl({ value: null, disabled: true }, Validators.required);

    this.document_type = new FormControl({ value: null, disabled: true }, Validators.required);
    this.nationality = new FormControl({ value: null, disabled: true }, Validators.required);
    this.department = new FormControl({ value: null, disabled: true }, Validators.required);
    this.province = new FormControl({ value: null, disabled: true }, Validators.required);
    this.district = new FormControl({ value: null, disabled: true }, Validators.required);

    this.cellPhone = new FormControl({ value: null, disabled: true }, [
      CustomValidators.number
    ]);
    this.email = new FormControl({ value: null, disabled: true }, [
      Validators.required,
      CustomValidators.email
    ]);
    this.agree_terms = new FormControl({ value: false, disabled: true }, Validators.requiredTrue);
    this.agree_exonerado = new FormControl({ value: false, disabled: true });
    this.agree_image = new FormControl({ value: false, disabled: true });

  }

  createForm() {
    this.visitorForm = new FormGroup({
      mall_id: this.mall_id,
      event_id: this.event_id,
      document: this.document,
      firstName: this.firstName,
      last_name: this.last_name,
      birthdate: this.birthdate,
      gender: this.gender,
      document_type: this.document_type,
      nationality: this.nationality,
      department: this.department,
      province: this.province,
      district: this.district,
      cellPhone: this.cellPhone,
      email: this.email,
      agree_terms: this.agree_terms,
      agree_exonerado: this.agree_exonerado,
      agree_image: this.agree_image,
    });
  }

  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  getGender() {
    this.apiService.getListGender().subscribe((options: any) => {
      this.options_gender = [];
      for (const option of options.data) {
        const select = new Select();
        select.name = option.name;
        select.value = option.id;
        this.options_gender.push(select);
      }
    }
    );
  }

  getExchange() {
    this.apiService.getListExchange().subscribe((options: any) => {
      this.options_exchange = [];
      if (options.data !== null) {
        for (const option of options.data) {
          const select = new Select();
          select.name = option.name;
          select.value = option.id;
          this.options_exchange.push(select);
        }
      }
    });
  }

  getDocumentType() {
    this.apiService.getListDocumentType().subscribe((options: any) => {
      this.options_document_type = [];
      for (const option of options.data) {
        const select = new Select();
        select.name = option.description;
        select.value = option.id;
        this.options_document_type.push(select);
      }
    }
    );
  }

  getCountry() {
    this.options_country = of(countryData);
  }

  getDepartment() {
    this.apiService.getListDepartment().subscribe((options: any) => {
      this.options_department = [];
      for (const option of options.data) {
        const select = new SelectUbigeo();
        select.name = option.name;
        select.value = option.id;
        select.code = option.code;

        this.options_department.push(select);
      }
    }
    );
    this.options_district = [];
    const select = new SelectUbigeo();
    select.name = 'OTRO';
    select.code = '00';
    select.value = '000000';
    this.options_district.push(select);
  }

  getProvince(code: any, codeProvince: any, codeDistrict: any) {
    let idProvince: any;
    this.apiService.getListProvince(code).subscribe(async (options: any) => {
      this.options_province = [];
      const select = new SelectUbigeo();
      select.name = 'OTRO';
      select.code = '0000';
      select.value = 0;
      this.options_province.push(select);
      if (codeProvince != null) {
        for (const option of options.data) {
          const select = new SelectUbigeo();
          select.name = option.name;
          select.value = option.id;
          select.code = option.code;
          if (option.code.substring(0, 4) == codeProvince) {
            idProvince = option.id;
            this.visitorForm.controls['province'].reset({ value: idProvince, disabled: false });
            this.getDistrict(idProvince, codeDistrict);
          }
          this.options_province.push(select);
        }
        if (codeProvince.substring(2, 4) == '00') {
          idProvince = 1;
          this.visitorForm.controls['province'].reset({ value: 0, disabled: false });
          this.getDistrict(idProvince, codeDistrict);
        }
      } else {
        for (const option of options.data) {
          const select = new SelectUbigeo();
          select.name = option.name;
          select.value = option.id;
          select.code = option.code;
          this.options_province.push(select);
        }
      }
    }
    );
  }

  getDistrict(code: any, codeDistrict: any) {
    this.apiService.getListDistrict(code).subscribe((options: any) => {
      this.options_district = [];
      const select = new SelectUbigeo();
      select.name = 'OTRO';
      select.code = '00';
      select.value = '000000';
      this.options_district.push(select);
      for (const option of options.data) {
        const select = new SelectUbigeo();
        select.name = option.name;
        select.code = option.id;
        select.value = option.code;
        this.options_district.push(select);
        if (codeDistrict != null) {
          this.visitorForm.controls['district'].reset({ value: codeDistrict, disabled: false });
        }
      }
      if (codeDistrict.substring(4, 6) === '00') {
        this.visitorForm.controls['district'].reset({ value: '000000', disabled: false });
      }
    }
    );
  }

  getEvents() {
    this.apiService.getListSpecialEvent().subscribe((options: any) => {
      this.options_event = [];
      if (options.data === null) {
        this.messagesService.error('No hay eventos activos para este Mall.');
        return;
      }

      for (const option of options.data) {
        const select = new SelectEvent();
        select.name = option.name;
        select.value = option.id;
        select.with_exoneration = option.with_exoneration;
        this.options_event.push(select);
      }

      if (this.options_event.length > 0) {
        this.events_error = false;
        if (this.options_event.length === 1) {
          this.visitorForm.controls['event_id'].reset({ value: this.options_event[0].value, disabled: false });
        }
      } else {
        this.events_error = true;
      }

    }
    );
  }

  onClickSearchClient() {
    if (this.visitorForm.invalid) {
      // tslint:disable-next-line:forin
      for (const i in this.visitorForm.controls) {
        this.visitorForm.controls[i].markAsDirty();
        this.visitorForm.controls[i].updateValueAndValidity();
      }

      return;
    }

    this.getDataStructureToForm(this.visitorForm.value.event_id);
    if (this.document.errors) {
      this.document.markAsTouched({ onlySelf: true });
      return;
    }
    this.visitorForm.controls['document_type'].reset({ value: 1, disabled: false });
    this.visitorForm.controls['nationality'].reset({ value: 'PE', disabled: false });
    this.apiService.getVisitorSearchByDni(this.visitorForm.value.document).subscribe((_response) => {
      this.signature = null;
      this.visilidad_spinner = true;
      this.data_user = ApiService.getDataUser();
      const response: any = _response;
      if (response.data) {
        let birthday;
        if (response.data.birthday == null) {
          birthday = '';
        } else {
          birthday = response.data.birthday;
          birthday = moment(birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
        }
        this.visitorForm.controls['firstName'].reset({ value: response.data.first_name, disabled: false });
        this.visitorForm.controls['last_name'].reset({ value: response.data.last_name, disabled: false });
        this.visitorForm.controls['email'].reset({ value: response.data.email, disabled: false });
        this.visitorForm.controls['cellPhone'].reset({ value: response.data.phone, disabled: false });
        this.visitorForm.controls['gender'].reset({ value: response.data.gender.id, disabled: false });
        this.visitorForm.controls['birthdate'].reset({ value: birthday, disabled: false });
        this.isNewVisitor = false;
        if (response.data.agree_terms == 1) {
          this.visitorForm.controls['agree_terms'].reset({ value: true, disabled: true });
          this.p_agree_terms = true;
        } else {
          this.visitorForm.controls['agree_terms'].reset({ value: false, disabled: false });
          this.p_agree_terms = false;
        }
        if (response.data.cod_ubigeo) {
          this.asingarUbigeo(response);
        } else {
          let codUbigeo: string = this.data_user.codUbigeo;
          if (codUbigeo == null || codUbigeo == undefined) {
            this.visitorForm.controls['department'].reset({ value: '', disabled: false });
            this.visitorForm.controls['province'].reset({ value: '', disabled: false });
            this.visitorForm.controls['district'].reset({ value: '', disabled: false });
          } else {
            const departmentCod = parseInt(codUbigeo.substring(0, 2));
            // this.visitorForm.get('department').setValue(departmentCod);
            this.visitorForm.controls['department'].reset({ value: departmentCod, disabled: false });
            this.getProvince(departmentCod, null, null);
            this.visitorForm.controls['province'].reset({ value: '', disabled: false });
            this.visitorForm.controls['district'].reset({ value: '', disabled: false });
          }
        }
        // this.visitorForm.value.agree_terms = response.data.agree_terms;
        this.visitorForm.controls['document_type'].reset({ value: response.data.document_type, disabled: false });
        this.visitorForm.controls['nationality'].reset({ value: response.data.nationality, disabled: false });

        this.visilidad_spinner = false;
        this.visilidad_formulario = true;
        this.messagesService.warning('El visitante con ese DNI ya se encuentra registrado.');

      } else {
        this.obtenerVisitantePorApiExterna();
      }
      this.isDisabled = false;

    },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }

  asingarUbigeo(response: any) {
    let codUbigeo = response.data.cod_ubigeo;
    console.log(codUbigeo);
    console.log(codUbigeo.substring(0, 4));
    const departmentCod = parseInt(codUbigeo.substring(0, 2));

    this.getProvince(departmentCod, codUbigeo.substring(0, 4), codUbigeo);
    this.visitorForm.controls['department'].reset({ value: departmentCod, disabled: false });

  }

  obtenerVisitantePorApiExterna() {
    this.isNewVisitor = true;
    this.visitorForm.controls['agree_terms'].reset({ value: false, disabled: false });
    this.p_agree_terms = false;
    this.apiService.getVisitorSearchByDniExterno(this.visitorForm.value.document).subscribe((_response) => {
      const response2: any = _response;
      if (response2.data) {
        this.visitorForm.controls['firstName'].reset({ value: response2.data.first_name, disabled: false });
        this.visitorForm.controls['last_name'].reset({ value: response2.data.last_name, disabled: false });
        this.messagesService.success('El DNI ha sido encontrado.');
      } else {
        this.visitorForm.controls['firstName'].reset({ value: null, disabled: false });
        this.visitorForm.controls['last_name'].reset({ value: null, disabled: false });
        this.messagesService.warning('El DNI no se encuentra registrado.');
      }
      this.visilidad_spinner = false;
      this.visilidad_formulario = true;
    },
      (errorResponse: HttpErrorResponse) => {
        this.visilidad_spinner = false;
        this.visilidad_formulario = true;
        this.messagesService.warning('El DNI no se encuentra registrado.');
        this.visitorForm.controls['firstName'].reset({ value: null, disabled: false });
        this.visitorForm.controls['last_name'].reset({ value: null, disabled: false });
      });
    this.visitorForm.controls['birthdate'].reset({ value: null, disabled: false });
    this.visitorForm.controls['email'].reset({ value: null, disabled: false });
    this.visitorForm.controls['cellPhone'].reset({ value: null, disabled: false });
    this.visitorForm.controls['gender'].reset({ value: null, disabled: false });
    this.visitorForm.controls['agree_exonerado'].reset({ value: false, disabled: false });
    this.visitorForm.controls['agree_image'].reset({ value: false, disabled: false });


    let codUbigeo: string = this.data_user.codUbigeo;
    console.log('codUbigeo ' + codUbigeo);
    if (codUbigeo == null || codUbigeo == undefined) {
      this.visitorForm.controls['department'].reset({ value: '', disabled: false });
      this.visitorForm.controls['province'].reset({ value: '', disabled: false });
      this.visitorForm.controls['district'].reset({ value: '', disabled: false });
    } else {
      const departmentCod = parseInt(codUbigeo.substring(0, 2));
      this.visitorForm.controls['department'].reset({ value: departmentCod, disabled: false });
      this.getProvince(departmentCod, null, null);
      this.visitorForm.controls['province'].reset({ value: 0, disabled: false });
      this.visitorForm.controls['district'].reset({ value: '000000', disabled: false });
    }

    this.visilidad_spinner = false;
    this.visilidad_formulario = true;
    this.messagesService.warning('El DNI no se encuentra registrado.');
  }

  openFromCheckbox(content, controlName?) {
    this.openModal = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'signer__board',
      backdrop: 'static'
    });
  }


  //After signing
  doCloseTerms(signerPad?) {
    // if (controlName || this.visitorForm.controls[controlName].disabled===false){
    //   this.visitorForm.controls[controlName].reset({value:true})
    // }
    this.signature = signerPad.signaturePad.toDataURL();
    if (this.signCheck === false) { //minimun length when signer is blank, is 4042
      this.signature = null;
      this.messagesService.error('Debe firmar.');
    }
    this.openModal.dismiss();
    this.signCheck = false;
  }

  drawBegin() {
    this.signCheck = true;
  }

  public get signature() {
    return this._signature;
  }

  public set signature(value) {
    this._signature = value;
  }

  onSelectedEvent(value: any) {
    this.options_event.forEach((event: any) => {
      if (event.value === value) {
        this.exonerador = event.with_exoneration === 1;
        this.imager = event.with_image === 1;
      }
    });
    this.modelForm.event_id = value;
    this.visilidad_formulario = false;
    this.clearForm();
  }

  onSelectedGender(value: any) {
    this.modelForm.gender = value;
  }

  onSubmit() {
    this.isDisabled = true;
    const sign = this.signature;
    if (!this.visitorForm.valid) {
      this.validateForm(this.visitorForm);
      this.isDisabled = false;
      return;
    }

    this.modelForm = { ...this.visitorForm.value, userSign: sign };
    this.modelForm.mall_id = this.data_user.mallId;
    if (this.visitorForm.value.district == '000000') {
      let department = this.visitorForm.value.department;
      department = department > 0 ? department : '0' + department;
      let province = this.visitorForm.value.province;
      province = province > 0 ? province : '0' + province;
      let district = this.visitorForm.value.district;
      district = district == '000000' ? '00' : district.substring(0, 2);
      this.modelForm.cod_ubigeo = department + province + district;
    } else {
      this.modelForm.cod_ubigeo = this.visitorForm.value.district;
    }
    // this.modelForm.agree_terms =  this.p_agree_terms;
    if (this.p_agree_terms) {
      this.modelForm.agree_terms = true;
    }
    /*const request = {
      "numberTicket" :  this.visitorForm.value.numberTicket,
      "numberPoints" : this.visitorForm.value.numberPoints,
      "exchange"     : this.visitorForm.value.exchange
    };*/

    if (this.dynamicalGroup != undefined) {
      const request = this.dynamicalGroup.value;
      this.modelForm.additional_data = JSON.parse(JSON.stringify(request));
      this.modelForm.additonal_data_est = true;
    } else {
      const request = {};
      this.modelForm.additional_data = JSON.parse(JSON.stringify(request));
      this.modelForm.additonal_data_est = false;
    }

    if (!(this.modelForm.birthdate == null) && (this.modelForm.birthdate.length > 0)) {
      this.modelForm.birthdate = moment(this.modelForm.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
      this.modelForm.birthdate = null;
    }

    if (this.isNewVisitor) {
      this.createVisitor();
      this.visilidad_formulario = false;
    } else {
      this.updateVisitor();
      this.visilidad_formulario = false;
    }
  }

  createVisitor() {
    this.apiService.postVisitorInsert(this.modelForm).subscribe((response) => {
      this.apiService.postEmailSend(this.modelForm).subscribe((email) => {
      });
      this.clearForm();
      this.messagesService.success('Se registro correctamente');
    },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.warning('Este ticket ya ha sido registrado');
      });
  }

  updateVisitor() {
    this.apiService.putVisitorInsert(this.modelForm).subscribe((response) => {

      if (this.firstName.dirty || this.last_name.dirty || this.email.dirty) {
        this.apiService.postEmailSend(this.modelForm).subscribe((email) => {
          console.log(email);
        });
      }
      this.clearForm();
      this.messagesService.success('Se actualizo correctamente');
    },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.warning('El ticket ya se encuentra registrado.');
      });

  }

  onSelectedDeparment(value: any) {
    this.getProvince(value, null, null);
  }

  onSelectedProvince(value: any) {
    this.getDistrict(value, null);
    this.options_district = [];
  }

  clearForm() {
    const mall_id: string = this.modelForm.mall_id;
    const event_id = this.modelForm.event_id;
    this.modelForm = new Visitor();
    this.modelForm.mall_id = mall_id;
    this.modelForm.event_id = event_id;
    this.isDisabled = true;
    this.isNewVisitor = true;


    this.visitorForm.controls['document'].reset({ value: null, disabled: false });
    this.visitorForm.controls['firstName'].reset({ value: null, disabled: true });
    this.visitorForm.controls['last_name'].reset({ value: null, disabled: true });
    this.visitorForm.controls['email'].reset({ value: null, disabled: true });
    this.visitorForm.controls['cellPhone'].reset({ value: null, disabled: true });
    this.visitorForm.controls['gender'].reset({ value: null, disabled: true });
    this.visitorForm.controls['birthdate'].reset({ value: null, disabled: true });
    this.visitorForm.controls['agree_terms'].reset({ value: false, disabled: true });
    this.visitorForm.controls['agree_exonerado'].reset({ value: false, disabled: true });
    this.visitorForm.controls['agree_image'].reset({ value: false, disabled: true });

    this.clearDynamicalForm();

  }

  openTerms(content) {
    this.openModal = this.modalService.open(content, { centered: true });
  }

  console(value: any) {
    console.log(value);
  }

  drawComplete(signerPad): void {
    //This should go to the send button
    // this.signature = signerPad.signaturePad.toDataURL();
    // console.log("ooot", this._signature)
  }

  getDataStructureToForm(eventId) {
    this.apiService.getDataStructure(eventId).subscribe((dataStructure) => {
      let dataObject = JSON.parse(dataStructure.data.resultadoJson);
      for (let entry of dataObject.properties.filter(m => m.type == 'select')) {
        entry.data.sort(this.compare);
      }
      this.buildingJsonOfForm(dataObject);
      this.buildingForm(dataObject);
    });
  }

  buildingForm(data: any): void {
    let form = data['properties'];
    let formGroup = {};

    form.map((elementOfForm) => {
      elementOfForm.required ? formGroup[elementOfForm.name] = [, Validators.required] : formGroup[elementOfForm.name] = [,];
    });

    this.dynamicalGroup = this.formBuilder.group(formGroup);
  }

  buildingJsonOfForm(data: any): void {

    this.jsonOfForm = data['properties'];

  }

  clearDynamicalForm() {
    this.jsonOfForm.map((elementOfForm) => {
      this.dynamicalGroup.controls[elementOfForm.name].reset({ value: null, disabled: true });
    });
  }

  compare(a: any, b: any) {
    if ((a.text === null || a.text === undefined ? '' : a.text).toUpperCase() <
      (b.text === null || b.text === undefined ? '' : b.text).toUpperCase())
      return -1;
    if ((a.text === null || a.text === undefined ? '' : a.text).toUpperCase() >
      (b.text === null || b.text === undefined ? '' : b.text).toUpperCase())
      return 1;

    return 0;
  }
}
