export class EventAssing {
  id_event: number;
  id_mall: number;
  date_start: string;
  date_end: string;
  with_exoneration: boolean;
  with_image: boolean;
  lunes: boolean;
  martes: boolean;
  miercoles: boolean;
  jueves: boolean;
  viernes: boolean;
  sabado: boolean;
  domingo: boolean;
}
