import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ResponseAccesos } from '../models/responseAccesos';
import { ResponseActivity, ResponseMall } from '../models/responseMall';
import { ResponseOptions } from '../models/responseMenu';
import { Visitor } from '../models/responseVisitor';
import { EventAssing } from './../models/responseEventAssing';
import { Evento } from './../models/responseEvento';
import { Select } from './../models/select';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  data_user: any;

  static getDataUser() {
    return JSON.parse(localStorage.getItem('data_user'));
  }

  //constructor(private http: HttpClient, private data_user: any) {
  //  data_user  = ApiService.getDataUser();
  //}

  constructor(private http: HttpClient) {
  }

  getUrlEcryt(email: string) {
    var key = CryptoJS.enc.Base64.parse('u/Gu5posvwDsXUnV5Zaq4g==');
    var iv = CryptoJS.enc.Base64.parse('5D9r9ZVzEYYgha93/aUK2w==');
    var text = email + '/15';
    var utf8Stringified = CryptoJS.enc.Utf8.parse(email + '/15');
    var encrypted = CryptoJS.AES.encrypt(
      text.toString(),
      key,
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: iv,
      });

    var string = this.replaceAll(encrypted.ciphertext.toString(CryptoJS.enc.Base64), '/', '*');
    return string;
  }

  replaceAll(string, search, replacement) {
    var target = string;
    return target.split(search).join(replacement);
  };

  getListMall(): Observable<ResponseMall[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_malls,
    });
    return this.http.get<ResponseMall[]>(environment.api.url_list_malls, { headers: reqHeader });
  }

  getValidCodigo(numberTicket, eventId): Observable<any> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_event_validate_code,
    });
    const data = eventId + '/valid?codigo=' + numberTicket;
    return this.http.get<any>(environment.api.url_event_validate_code + data, { headers: reqHeader });
  }

  getDataStructure(eventId): Observable<any> {
    const userData = ApiService.getDataUser();
    const reqHeader = new HttpHeaders({
      'Authorization': `${userData.token_type} ${userData.access_token}`
    });
    const data = eventId;
    return this.http.get<any>(environment.api.url_event_get_data_structure + data, { headers: reqHeader });
  }

  getListGender(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_gender,
    });

    return this.http.get<ResponseActivity[]>(environment.api.url_list_gender, { headers: reqHeader });
  }

  getListDocumentType(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_document_type,
    });

    return this.http.get<ResponseActivity[]>(environment.api.url_list_document_type, { headers: reqHeader });
  }

  getListDepartment(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_department,
    });

    return this.http.get<ResponseActivity[]>(environment.api.url_list_department, { headers: reqHeader });
  }

  getListProvince(code: any): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_province,
    });

    return this.http.get<ResponseActivity[]>(this.replaceAll(environment.api.url_list_province, '{departmentId}', code), { headers: reqHeader });
  }

  getListDistrict(code: any): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_district,
    });

    return this.http.get<ResponseActivity[]>(this.replaceAll(environment.api.url_list_district, '{provinceId}', code), { headers: reqHeader });
  }


  getListExchange(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_exchange,
    });

    return this.http.get<ResponseActivity[]>(environment.api.url_list_exchange, { headers: reqHeader });
  }

  getListEvent(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_event,
    });
    this.data_user = ApiService.getDataUser();

    return this.http.get<ResponseActivity[]>(environment.api.url_list_event + this.data_user.mallId + '/events', { headers: reqHeader });
  }

  getEventByMall(mallID): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_event,
    });
    this.data_user = ApiService.getDataUser();

    return this.http.get<ResponseActivity[]>(environment.api.url_list_event + mallID + '/events?event_finished=1&event_all=1', { headers: reqHeader });
  }

  getListSpecialEvent(): Observable<ResponseActivity[]> {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_special_event,
    });
    this.data_user = ApiService.getDataUser();

    return this.http.get<ResponseActivity[]>(environment.api.url_list_special_event + this.data_user.mallId + '/event-specials', { headers: reqHeader });
  }

  getEventTypes() {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_special_event,
    });

    return this.http.get(environment.api.url_event_type, { headers: reqHeader });
  }

  getAllVisitors(nombre?: string, dni?: string, mallId?: string, event?: string, dateStart?, dateEnd?, eventType?) {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_special_event,
    });
    this.data_user = ApiService.getDataUser();
    let headers = new HttpParams();
    if (nombre) {
      headers = headers.append('nombre', nombre);
    }
    if (dni) {
      headers = headers.append('dni', dni);
    }
    if (mallId) {
      headers = headers.append('mallId', mallId);
    }
    if (event) {
      headers = headers.append('event', event);
    }
    if (dateStart) {
      headers = headers.append('dateStart', dateStart);
    }
    if (dateEnd) {
      headers = headers.append('dateEnd', dateEnd);
    }
    if (eventType) {
      headers = headers.append('eventType', eventType);
    }

    return this.http.get(environment.api.url_visitor_search, { params: headers, headers: reqHeader });
  }

  getVisitorDetail(document) {
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_list_special_event,
    });
    this.data_user = ApiService.getDataUser();
    // let headers = new HttpParams();
    // headers.append('document', document)

    return this.http.get(`${environment.api.url_visitor_detail}/${document}/details`, { headers: reqHeader });
  }

  getVisitorSearchByDni(identity_document?: string) {

    const _params = new HttpParams().set('document', identity_document);
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_visitor_search_by_bni,
    });

    return this.http.get<Visitor>(environment.api.url_visitor_search_by_bni, { headers: _headers, params: _params });
  }

  getVisitorSearchByDniExterno(identity_document?: string) {

    const _params = new HttpParams().set('document', identity_document);
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_visitor_search_by_bni,
    });

    return this.http.get<Visitor>(environment.api.url_visitor_search_by_bni_externo, {
      headers: _headers,
      params: _params
    },);
  }

  postVisitorInsert(visitor: Visitor) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'mallId': data_user.mallId,
      'eventId': visitor.event_id,
      'eventExoneration': visitor.agree_exonerado,
      'eventImage': visitor.agree_image,
      'userSign': visitor.userSign,
      'visitor': {
        'document': visitor.document,
        'firstName': visitor.firstName,
        'lastName': visitor.last_name,
        'genderId': visitor.gender,
        'email': visitor.email,
        'phone': visitor.cellPhone,
        'agreeTerms': visitor.agree_terms,
        'birthday': visitor.birthdate,
        'documentType': visitor.document_type,
        'nationality': visitor.nationality,
        'codUbigeo': visitor.cod_ubigeo,
      },
      'additionalDataEst': visitor.additonal_data_est,
      'additionalData': visitor.additional_data,
      'userId': parseInt(data_user.codigo_unico),
      'userFullName': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };


    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_visitor_insert,
    });

    return this.http.post<any>(environment.api.url_visitor_insert, request, { headers: _headers });
  }

  putVisitorInsert(visitor: Visitor) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'mall_id': data_user.mallId,
      'event_id': visitor.event_id,
      'event_exoneration': visitor.agree_exonerado,
      'event_image': visitor.agree_image,
      'visitor': {
        'document': visitor.document,
        'first_name': visitor.firstName,
        'last_name': visitor.last_name,
        'gender_id': visitor.gender,
        'email': visitor.email,
        'phone': visitor.cellPhone,
        'agree_terms': visitor.agree_terms,
        'birthday': visitor.birthdate,
        'cod_ubigeo': visitor.cod_ubigeo,
        'nationality': visitor.nationality,
        'document_type': visitor.document_type
      },
      'user_sign': visitor.userSign,
      'additional_data_est': visitor.additonal_data_est,
      'additional_data': visitor.additional_data,
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };


    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_visitor_update,
    });
    return this.http.put<any>(environment.api.url_visitor_update, request, { headers: _headers });
  }


  postEmailSend(data: any) {
    const request = {
      'addressSender': environment.api.email_sender,
      'addressTo': [
        data.email
      ],
      'addressCc': [],
      'subject': 'Gracias por Registrarse - Real Plaza',
      'template': {
        'folderName': 'registro-clientes',
        'fileName': 'bienvenido.html'
      },
      'data': {
        'name': data.firstName,
        'surnames': data.last_name
        // 'url' : environment.api.url_unsubscribe+ this.getUrlEcryt(data.email),
      }
    };

    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_email_send,
    });
    return this.http.post<any>(environment.api.url_email_send, request, { headers: _headers });
  }

  postEmailConsentimientoUsoImageSend(data: any) {
    const request = {
      'addressSender': environment.api.email_sender,
      'addressTo': [
        data.email
      ],
      'addressCc': [],
      'subject': 'Consentimiento de Uso de Imagen',
      'template': {
        'folderName': 'registro-clientes',
        'fileName': 'consentimiento-uso-imagen.html'
      },
      'data': {
        'name': data.firstName,
        'surnames': data.last_name
        // 'url' : environment.api.url_unsubscribe+ this.getUrlEcryt(data.email),
      }
    };

    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_email_send,
    });
    return this.http.post<any>(environment.api.url_email_send, request, { headers: _headers });
  }


  putEventAssing(event: EventAssing) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'event': {
        'event_start': event.date_start,
        'event_end': event.date_end,
        'with_exoneration': event.with_exoneration,
        'with_image': event.with_image,
        'date_weekend': [{ 'est': event.lunes }, { 'est': event.martes }, { 'est': event.miercoles },
        { 'est': event.jueves }, { 'est': event.viernes }, { 'est': event.sabado },
        { 'est': event.domingo }],
      },
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };
    const url = event.id_event + '/details/' + event.id_mall;

    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_event_assing_update,
    });

    return this.http.put<any>(environment.api.url_event_assing_update + url, request, { headers: _headers });
  }

  postEventAssing(event: number, malls: Select[], specify_date: any) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const mall = [];
    const specifyDate = [];
    malls.forEach(element => {
      mall.push({ 'id': element.value });
    });
    specify_date.forEach(element => {
      const date_weekend = [];
      element.date_weekend.forEach(element => {
        date_weekend.push({ 'est': element });
      });
      specifyDate.push({
        'event_start': element.date_start, 'event_end': element.date_end,
        'with_exoneration': element.with_exoneration_boolean,
        'with_image': element.with_image_boolean,
        'date_weekend': date_weekend
      });
    });
    const request = {
      'malls': mall,
      'specifyDate': specifyDate,
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };
    const url = event + '/details';
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_event_assing_insert,
    });

    return this.http.post<any>(environment.api.url_event_assing_insert + url, request, { headers: _headers });
  }

  postEventInsert(event: Evento) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'event': {
        'name': event.name_event,
        'event_type_id': event.event_type_id
      },
      'mall_id': event.id_mall,
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };


    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_evento_insert,
    });
    //Comentario
    return this.http.post<any>(environment.api.url_evento_insert, request, { headers: _headers });
  }

  getListarMallEvent(id: number) {
    const data = id + '/details';
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_mall_evento_list,
    });
    return this.http.get<any>(environment.api.url_mall_evento_list + data, { headers: _headers });
  }

  getListarEventMall(id: number, estado: number) {
    if (estado == 2) {
      const data = '?event_type=2&not_assigned=1';
      const _headers = new HttpHeaders({
        'x-api-key': environment.api.token_url_evento_list,
      });
      return this.http.get<any>(environment.api.url_evento_list + data, { headers: _headers });
    } else {
      const data = id + '/events?event_finished=1';
      const _headers = new HttpHeaders({
        'x-api-key': environment.api.token_url_evento_mall_list,
      });
      return this.http.get<any>(environment.api.url_evento_mall_list + data, { headers: _headers });
    }

  }

  getListEventNoAssigned(id: number) {
    let data;
    if (id == 1) {
      data = '?event_type=1&not_assigned=1';
    } else {
      data = '?event_type=2';
    }
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_evento_list,
    });

    return this.http.get<any>(environment.api.url_evento_list + data, { headers: _headers });
  }

  getListarEvent(id: number) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));

    const data = '?event_type=1&event_type=2&with_trash=0';
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_evento_list,
    });

    return this.http.get<any>(environment.api.url_evento_list + data, { headers: _headers });

  }

  updateEvent(id: number, event: Evento) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'event': {
        'name': event.name_event,
        'event_type_id': event.event_type_id
      },
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_evento_update,
    });
    return this.http.put<any>(environment.api.url_evento_update + id, request, { headers: _headers });
  }

  deleteAssingEvent(idEventMall: number, idEvent) {

    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const url = idEvent + '/details/' + idEventMall;

    const request = {
      'user_id': data_user.codigo_unico,
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_event_assing_delete,
    });
    return this.http.request('DELETE', environment.api.url_event_assing_delete + url, {
      headers: _headers,
      body: request
    });
  }

  deleteEvent(id: number) {
    const data_user = JSON.parse(localStorage.getItem('data_user'));
    const request = {
      'user_id': parseInt(data_user.codigo_unico),
      'user_fullname': data_user.user_lastname + ' ' + data_user.user_mother_lastname + ', ' + data_user.user_names
    };
    const _headers = new HttpHeaders({
      'x-api-key': environment.api.token_url_evento_delete,
    });

    return this.http.request('DELETE', environment.api.url_evento_delete + id, {
      headers: _headers,
      body: request
    });
  }

  getListMenu(): Observable<ResponseOptions> {
    const user = ApiService.getDataUser();
    const data = '?user_c_vusuario=' + user.email + '&sistema=' + environment.api.code_system;
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_menu
    });
    return this.http.get<ResponseOptions>(environment.api.url_menu + data, { headers: reqHeader });
  }

  getUsuarioSistema(): Observable<any> {
    const user = ApiService.getDataUser();
    const data = '?user_c_iid=' + user.codigo_unico + '&sistema=' + environment.api.code_system;
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_usuario_sistema
    });
    return this.http.get<any>(environment.api.url_usuario_sistema + data, { headers: reqHeader });
  }

  getAccesoDirecto(idperfiles): Observable<ResponseAccesos> {
    const data = '?idPerfiles=' + idperfiles;
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.api.token_url_acceso_directo
    });
    return this.http.get<ResponseAccesos>(environment.api.url_acceso_directo + data, { headers: reqHeader });
    // return of(dashboard_content);
  }

  refreshToken(): Observable<any> {
    const user = ApiService.getDataUser();
    const data = new HttpParams()
      .set('grant_type', environment.refresh_credentials.grant_type)
      .set('client_id', environment.refresh_credentials.client_id)
      .set('client_secret', environment.refresh_credentials.client_secret)
      .set('refresh_token', user.refresh_token);

    const reqHeader = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(environment.refresh_credentials.url, data, { headers: reqHeader });
    // localStorage.setItem('data_user',JSON.stringify(result));
  }

  exportExcel(nombre?, dni?, mall?, event?, dateStart?, dateEnd?, eventType?): Observable<Blob> {
    const headers = new HttpHeaders({ 'x-api-key': environment.api.token_url_list_special_event });
    const mallName = mall ? mall.Inmueble : null;
    const eventName = event ? event.name : null;
    const eventTypeName = eventType ? eventType.name : null;
    let params = new HttpParams();
    if (nombre) params = params.append('nombre', nombre);
    if (dni) params = params.append('dni', dni);
    if (mall) params = params.append('mallId', mall.CodInmueble);
    if (event) params = params.append('event', event.id);
    if (dateStart) params = params.append('dateStart', dateStart);
    if (dateEnd) params = params.append('dateEnd', dateEnd);
    if (eventType) params = params.append('eventType', eventType.id);
    return this.http.post(environment.api.url_visitor_export, { mallName, eventName, eventTypeName }, { params, headers, responseType: 'blob' });
  }

}
