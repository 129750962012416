import {Component, EventEmitter, Inject, Injectable, Input, Optional, Output, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

const moment = _rollupMoment || _moment;

@Injectable()
export class MyMomentDateAdapter extends MomentDateAdapter {

    constructor( @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
        super(dateLocale);
    }

    getMonthNames(style) {
        return ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
    }
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.styl'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: DateAdapter, useClass: MyMomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class DatepickerComponent {
  date = new FormControl();
  date_aux = new FormControl(moment().subtract(1, 'months'));

  @Input() disabled = false;
  @Input() placeholder: String = 'Seleccionar Fecha';
  @Output() selected_m = new EventEmitter<string>();
  @Output() selected_y = new EventEmitter<string>();
  @Output() selected_txt = new EventEmitter<object>();

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('es');
  }
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date_aux.value;
    ctrlValue.year(normalizedYear.year());
    this.selectionY(normalizedYear.year());
    this.date.setValue(ctrlValue);
  }
  chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date_aux.value;
    ctrlValue.month(normlizedMonth.month());
    this.date.setValue(ctrlValue);
    this.selectionM(normlizedMonth.month());
    datepicker.close();
  }
  changeDatePicker(value: Moment) {
    this.selected_txt.emit({
      output: value.toDate(),
      display: value.format(MY_FORMATS.display.dateInput)
    });
  }
  selectionM(value) {
    this.selected_m.emit(value);
  }
  selectionY(value) {
    this.selected_y.emit(value);
  }
}
