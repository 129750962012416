import { Component, OnInit,ViewChild,Output ,EventEmitter } from '@angular/core';
import {FormControl, FormGroup, Validators,FormBuilder,NgForm,FormGroupDirective} from '@angular/forms';
import {EventAssing} from '../../../../../models/responseEventAssing';
import * as _moment from 'moment';
import {MatTableDataSource, MatSort,MatSelect} from '@angular/material';
import {default as _rollupMoment, Moment} from 'moment';
import {MessagesService} from '../../../../../services/messages.service';
import {ApiService} from '../../../../../services/api.service';
import {Select} from '../../../../../models/select';
import {HttpErrorResponse} from '@angular/common/http';
import {SelectionModel} from '@angular/cdk/collections';
import {ListMallDate} from '../../../../../models/listMallDate';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';

function validateDate(c: FormControl) {

  let isValid = null;
  if (c.value == null || c.value == undefined  || c.value == '' || c.value.lenght<1)   {
    isValid = true;
  }
  else{
    if (!moment(c.value, 'DD-MM-YYYY').isValid()) {
      isValid = null;
    } else {
      isValid = true;
      const fechaInitMoment = moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
      const FechaCompare = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (fechaInitMoment >= FechaCompare) {
        isValid = null;
      } else {
        isValid = true;
      }
    }
  }
  return isValid ? null : {
    validateDate: {
      valid: false
    }
  };

}

@Component({
  selector: 'app-assig-event-mall',
  templateUrl: './assig-event-mall.component.html',
  styleUrls: ['./assig-event-mall.component.styl']
})



export class AssigEventMallComponent implements OnInit {
  mask = {
    guide: false,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };
  p_event_name : string;
  options_mall: Select[];
  options_event: Select[];
  list_event: ListMallDate[];
  isDisabled: boolean;
  event_id_select: FormControl;
  secondCtrl: FormControl;
  id_event: FormControl;
  event_name: FormControl;
  with_exoneration: FormControl;
  with_image: FormControl;
  date_end: FormControl;
  date_start: FormControl;
  modelForm: EventAssing = new EventAssing();
  selectForm: FormGroup;
  eventForm: FormGroup;
  especifyDate: FormGroup;
  secondFormGroup: FormGroup;
  options_mall_selected: Select[];
  dataSourceSpecifyDate = new MatTableDataSource<SpecifyDate>(DATE_DATA);
  dataSourceAddMall = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  displayedColumnsDate: string[] = ['exoneration', 'image',  'date_start', 'date_end',  'date_weekend','id'];
  displayedColumns: string[] = ['id', 'name'];

  public active: boolean = false;
  public body: string;
  public title: string;
  public onClose: Subject<boolean>;

  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  private banks: Select[] = [{value: "-1", name : "Escriba para buscar eventos" }]
  private _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);

  @ViewChild('formDirective') public myNgForm: NgForm;
  @ViewChild('singleSelect') singleSelect: MatSelect; 

  constructor(
    private messagesService: MessagesService,
    private apiService: ApiService,
    public bsModalRef: BsModalRef,
    private _formBuilder: FormBuilder) {
  }


  getEvents() {
    this.apiService.getListEventNoAssigned(2).subscribe(response => {
      this.banks = [];
      const options: any = response.data;
      for (const option of options) {
        const select = new Select();
        select.name = option.name;
        select.value = option.id;
        this.banks.push(select);
      }
    });
  }
  getMall() {
    this.options_mall = [];
    this.apiService.getListMall().subscribe(response => {
      let options: any = response.filter(mall => {
        const exclude_malls = [4, 8, 73, 77, 76, 78];
        const mall_id = mall.CodInmueble;
        if (!exclude_malls.includes(mall_id)) {
          return mall;
        }
      });

      options = options.sort(function (a, b) {
        if (a.Inmueble > b.Inmueble) {
          return 1;
        }
        if (a.Inmueble < b.Inmueble) {
          return -1;
        }
        return 0;
      });
      for (const option of options) {
        const select = new Select();
        select.name = option.Inmueble;
        select.value = option.CodInmueble;
        this.options_mall.push(select);
      }
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getEvents();
    this.getMall();
    this.isDisabled = false;
    
    this.onClose = new Subject();

    this.filteredBanks.next(this.banks.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }
  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setInitialValue() {
    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {          
        this.singleSelect.compareWith = (a: Select, b: Select) => a.value === b.value;
      });
  }
  private filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  createFormControls() {
    this.date_start = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.date_end = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.event_name = new FormControl(null);
    this.with_exoneration = new FormControl(false, [ ]);
    this.with_image = new FormControl(false, [ ]);
    this.secondCtrl = new FormControl(null, [  Validators.required,]);
    this.event_id_select = new FormControl(null, [  Validators.required,]);
  }

  createForm() {
    this.selectForm = this._formBuilder.group({
      event_id_select : this.event_id_select
    })
    this.especifyDate = new FormGroup({
      date_start : this.date_start,
      date_end : this.date_end,
      event_name : this.event_name,
      with_exoneration: this.with_exoneration,
      with_image: this.with_image,
      lunes : new FormControl(true, []),
      martes : new FormControl(true, []),
      miercoles : new FormControl(true, []),
      jueves : new FormControl(true, []),
      viernes : new FormControl(true, []),
      sabado : new FormControl(true, []),
      domingo : new FormControl(true, []),
    }, { updateOn: 'blur' })
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: this.secondCtrl,
    });
  }

  addSpecifyDates(element: any,formDirective:FormGroupDirective){
    if (element == "add_circle") {
      if (!this.especifyDate.valid) {
        this.validateForm(this.especifyDate);
        this.isDisabled = false;
        return;
      }
      this.modelForm = this.especifyDate.value;
      let exoSimbol; let date_weekend = []; let exoMsg; 
      let imgSimbol; let imgMsg; 
      if (this.modelForm.with_exoneration) {
        exoSimbol = 'verified_user';
        exoMsg= 'Con exoneración de responsabilidades';
      } else {
        exoSimbol = 'report';
        exoMsg = 'Sin exoneración de responsabilidades';
      }
      if (this.modelForm.with_image) {
        imgSimbol = 'verified_user';
        imgMsg= 'Con consentimiento de uso de imagen';
      } else {
        imgSimbol = 'report';
        imgMsg = 'Sin consentimiento de uso de imagen';
      }

      date_weekend.push(this.modelForm.lunes);
      date_weekend.push(this.modelForm.martes);
      date_weekend.push(this.modelForm.miercoles);
      date_weekend.push(this.modelForm.jueves);
      date_weekend.push(this.modelForm.viernes);
      date_weekend.push(this.modelForm.sabado);
      date_weekend.push(this.modelForm.domingo);

      const row ={id: DATE_DATA.length,date_start : this.modelForm.date_start,date_end: this.modelForm.date_end,
      date_weekend: date_weekend ,
      with_exoneration_boolean: this.modelForm.with_exoneration, with_exoneration: exoSimbol, 
      with_exoneration_msg: exoMsg ,
      with_image_boolean: this.modelForm.with_image, with_image: imgSimbol, 
      with_image_msg: imgMsg ,
         simbol: 'delete',color:'warn'};
      DATE_DATA.push(row);


      this.especifyDate.reset({ with_exoneration:false,with_image:false,date_start: null, date_end: null,
        lunes:true, martes: true,
        miercoles: true, jueves: true, viernes: true,  sabado:true, domingo:true },
       { emitEvent: false }
       );
       this.especifyDate.controls['event_name'].reset({value: this.p_event_name, disabled: true});
      this.dataSourceSpecifyDate = new MatTableDataSource<SpecifyDate>(DATE_DATA);
    }
    else{
      DATE_DATA.splice(element.id,1);
      let position = 0;
      DATE_DATA.forEach(element => {
        element.id = position;
        position = position +1;
      });
      this.dataSourceSpecifyDate = new MatTableDataSource<SpecifyDate>(DATE_DATA);
    }

    if (DATE_DATA.length>0) {
      this.secondFormGroup.controls['secondCtrl'].reset({value: 'true', disabled: false});
    }else{
      this.secondFormGroup.controls['secondCtrl'].reset({value: null, disabled: false});
    }
    
  }
  onclickAsignarEvento() {
    this.options_mall_selected = [];
    this.selection.selected.forEach(element => {
      const row = new Select();
      row.name = element.name;
      row.value = element.id;
      this.options_mall_selected.push(row);
    });

    DATE_DATA.forEach(element => {
      element.date_start = moment(element.date_start, 'DD/MM/YYYY').format('YYYY-MM-DD');
      element.date_end = moment(element.date_end, 'DD/MM/YYYY').format('YYYY-MM-DD')
    });

    this.apiService.postEventAssing(this.selectForm.value.event_id_select.value, this.options_mall_selected, DATE_DATA).subscribe((response) => {
        this.messagesService.success('El evento se asigno con exito.');
        this.selectForm.controls['event_id_select'].reset({value: null, disabled: false});
        this.modelForm  = new EventAssing();

        ELEMENT_DATA = [];
        this.selection.selected.splice(0,100);
        DATE_DATA = [];
        this.dataSourceSpecifyDate = new MatTableDataSource<SpecifyDate>(DATE_DATA);
  
        this.active = false;
        this.onClose.next(true);
        this.bsModalRef.hide();
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });

  }
 
  onSelectedEvent() {
    if (this.selectForm.value.event_id_select.name != null) {
      this.p_event_name = this.selectForm.value.event_id_select.name;
    }
    

    this.especifyDate.controls['event_name'].reset({value: this.p_event_name, disabled: true});
    this.list_event = [];
    this.apiService.getListarMallEvent(this.selectForm.value.event_id_select.value).subscribe((response) => {
        const cadena = response.data;
        let position = 0;
        cadena.forEach(element => {
          position = position + 1;
          const list = new ListMallDate();
          list.position = position;
          list.id = element.mall_id;
          list.date_start = moment(element.date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
          list.date_end = moment(element.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
          list.with_exoneration_boolean = element.with_exoneration === '1' ? true : false;
          if (element.with_exoneration) {
            list.with_exoneration = 'verified_user';
            list.with_exoneration_msg = 'Con exoneración de responsabilidades';
          } else {
            list.with_exoneration = 'report';
            list.with_exoneration_msg = 'Sin exoneración de responsabilidades';
          }  
          list.with_image_boolean = element.with_image === '1' ? true : false;
          if (element.with_image) {
            list.with_image = 'verified_user';
            list.with_image_msg = 'Con consentiemto de uso de imagen';
          } else {
            list.with_image = 'report';
            list.with_image_msg = 'Sin consentimiento de uso de imagen';
          }  
          list.name = element.name_mall;

          this.list_event.push(list);
        });
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al obtener los datos, por favor actualize la pagina.');
      });
  }
  generarAssing() {

    if (!this.selectForm.valid) {
      this.validateForm(this.eventForm);
      return;
    }
    const list_tmp = this.list_event;
    this.list_event = [];

    this.options_mall.forEach(mall => {
        let position = 0;
        position = position + 1;
        const list = new ListMallDate();
        list.position = position;
        list.name = mall.name;
        list.date_start = '';
        list.date_end = '';
        list.with_exoneration_boolean = false;
        list.with_exoneration = 'verified_user';
        list.with_image_boolean = false;
        list.with_image = 'verified_user';

        list.id = mall.value;
        this.list_event.push(list);

    });


    ELEMENT_DATA = this.list_event;
    this.dataSourceAddMall = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  }


  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceAddMall.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceAddMall.data.forEach(row => this.selection.select(row));
  }


}


export interface PeriodicElement {
  position: number;
  name: string;
  date_start: string;
  date_end: string;
  with_exoneration: string;
  with_exoneration_boolean: boolean;
  with_exoneration_msg: string;

  with_image: string;
  with_image_boolean: boolean;
  with_image_msg: string;
  id: number;
}

export interface SpecifyDate{
  id: number;
  date_start: string;
  with_exoneration : string;
  with_exoneration_msg: string;
  with_image: string;
  with_image_msg: string;
  date_end: string;
  date_weekend: boolean[];
  simbol: string;
  color: string;
}


let ELEMENT_DATA: PeriodicElement[] = [];

const moment = _rollupMoment || _moment;
let DATE_DATA: SpecifyDate[] = [];