import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiService} from '../../../services/api.service';
import {Select} from '../../../models/select';
import {SelectMueble} from '../../../models/selectMueble';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.styl']
})
export class FormLoginComponent implements OnInit {

  isLoginError = false;
  message_error = '';
  loading = false;

  email: FormControl;
  password: FormControl;
  mall: FormControl;
  formlogin: FormGroup;
  nombreMall: String;
  codUbigeo: String;
  options_mall: SelectMueble[];
  option_tipo: Select[];

  constructor(private authService: AuthService, private apiService: ApiService, private router: Router, private cookieService: CookieService) {
  }


  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getMalls();
    this.getTipoMalls();
  }

  getTipoMalls() {
    this.option_tipo = [];

    const select = new Select();
    select.name = 'Todos';
    select.value = 0;
    this.option_tipo.push(select);

    select.name = 'Transversal';
    select.value = 1;
    this.option_tipo.push(select);

    select.name = 'Mall';
    select.value = 2;
    this.option_tipo.push(select);
  }

  getMalls() {
    this.apiService.getListMall().subscribe(response => {
      this.options_mall = [];
      const select = new SelectMueble();
      select.name = 'CORPORATIVO';
      select.value = 0;
      select.nameAbre = 'CORP';
      select.codUbigeo = '150113';
      this.options_mall.push(select);

      let options: any = response.filter(mall => {
        const exclude_malls = [4, 8, 73, 77, 76, 78];
        const mall_id = mall.CodInmueble;
        if (!exclude_malls.includes(mall_id)) {
          return mall;
        }
      });

      options = options.sort(function (a, b) {
        if (a.Inmueble > b.Inmueble) {
          return 1;
        }
        if (a.Inmueble < b.Inmueble) {
          return -1;
        }
        return 0;
      });

      for (const option of options) {
        const selectMueble = new SelectMueble();
        selectMueble.name = option.Inmueble;
        selectMueble.value = option.CodInmueble;
        selectMueble.nameAbre = option.NameAbrev;
        selectMueble.codUbigeo = option.CodUbigeo;
        this.options_mall.push(selectMueble);
      }
    }, (err: HttpErrorResponse) => {
      console.log(err.error.error_description);
    });
    // this.options_mall = [];
    // const select = new Select();
    //       select.name = "Mini Mi";
    //       select.value = 1;
    //       this.options_mall.push(select);
  }

  onSelectedMall(value: any) {
    this.options_mall.forEach((mall: any) => {
      if (mall.value === value) {
        if (mall.name === 'CORPORATIVO') {
          this.nombreMall = mall.name;
          this.codUbigeo = mall.codUbigeo;
        } else {
          let mallName = mall.name;
          mallName = mallName.toString();
          mallName = mallName.substring(11);
          this.nombreMall = mallName;
          this.codUbigeo = mall.codUbigeo;
        }

      }
    });
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')
    ]);
    this.password = new FormControl('', [
      Validators.required
    ]);
    this.mall = new FormControl('', [
      Validators.required
    ]);
  }

  createForm() {
    this.formlogin = new FormGroup({
      email: this.email,
      password: this.password,
      mall: this.mall
    });
  }

  OnSubmit(userName, password, mall) {
    this.loading = true;
    this.authService.authenticate(userName, password).subscribe((result: any) => {
        if (result) {
          this.checkProfileAccess(result.codigo_unico, result, userName, password, mall, this.nombreMall, this.codUbigeo);
        } else {
          this.isLoginError = true;
          this.message_error = result.message;
        }
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.message_error = (err.error.error_description === 'El usuario o password es incorrecto.') ? 'El usuario o contraseña es incorrecta' : 'Ocurrió un error inesperado';
        this.loading = false;
      });
  }

  checkProfileAccess(code, result, userName, password, mall, nombreMall, codUbigeo) {
    this.authService.checkProfileAccess(code).subscribe(profile => {
        if (profile[0].Perfiles) {
          result.email = userName;
          result.cypher = password;
          result.mallId = mall;
          result.mallName = nombreMall;
          result.codUbigeo = codUbigeo;
          localStorage.setItem('data_user', JSON.stringify(result));
          this.router.navigate(['/dashboard']);

          const expireTime = new Date();
          let time = expireTime.getTime();
          time += result.expires_in * 1000;
          expireTime.setTime(time);

          this.cookieService.set('access_token', result.access_token, expireTime, '/');
        } else {
          this.isLoginError = true;
          this.message_error = 'No cuenta con privilegios sobre el sistema';
        }
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        this.isLoginError = true;
        this.message_error = 'Ocurrió un error inesperado';
        this.loading = false;
      });
  }

}
