import { Component, OnInit } from '@angular/core';
import { ApiService } from './../../services/api.service';
import { MessagesService } from './../../services/messages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ResponseAccesos } from './../../models/responseAccesos';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.styl']
})
export class DashboardComponent implements OnInit {

  dashboard: ResponseAccesos[] = [];

  info_route = {
    breadcrumb_level: 1,
    breadcrumb_icon_name: 'home',
    breadcrumb_title: 'Dashboard',
    breadcrumb_subtitle: 'Dashboard'
  };

  constructor(private apiService: ApiService, private messagesService: MessagesService) { }

  ngOnInit() {
    this.apiService.getUsuarioSistema().subscribe( (options) => {
          this.apiService.getAccesoDirecto(options[0].Perfiles).subscribe((dashboard_content: any) => {
            console.log(dashboard_content);
            console.log(dashboard_content.options.menu.length > 1);
            if ( dashboard_content.options.menu.length > 1 ){
            dashboard_content.options.menu.forEach(element => {
              this.dashboard.push(element);
            });}
            else{
            this.dashboard = [ dashboard_content.options.menu ]
            }
            // this.dashboard_content = dashboard_content;
          });
       },
       (err: HttpErrorResponse) => {
          this.messagesService.error('Ocurrió un error inesperado con nuestro proveedor');
       }
    );
  }

  getColor() {
    // if (typeof this.dashboard_content.properties !== 'undefined') {
    //   return this.dashboard_content.properties.color;
    // } else {
      return 'purple';
    // }
  }

}
