import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { SelectEvent } from 'src/app/models/selectEvent';
import { ApiService } from 'src/app/services/api.service';
import { ResponseActivity } from '../../models/responseMall';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.scss']
})
export class AuditoriaComponent implements OnInit {
  $allEvents: Observable<ResponseActivity[]>;
  $filteredEvents: Observable<ResponseActivity[]>;
  info_route = {
    breadcrumb_level: 1,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Auditoria',
    breadcrumb_subtitle: 'Auditoria'
  };
  showAdFilters = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  visilidad_spinner = false;
  visilidad_error = false;
  error_text = 'Por favor seleccione más filtros.';
  malls = [];
  name = '';
  dni = '';
  events = [];
  eventTypes = [];
  ELEMENT_DATA = [];
  allVisitants = [];
  adForm = new FormGroup({
    dateMin: new FormControl(null),
    dateMax: new FormControl(null),
    selMall: new FormControl(null),
    eve: new FormControl(null),
    eventType: new FormControl(null),
  });
  displayedColumns: string[] = ['name', 'lastname', 'weight', 'sign'];
  dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getEvents(ApiService.getDataUser().mallId);
    this.adForm.get('selMall').valueChanges.subscribe((newVal) => {
      this.eve.patchValue(null);
      this.getEvents(newVal);
    });
    this.getMalls();
  }

  get selMall() {
    return this.adForm.controls.selMall as FormControl;
  }

  get dateMin() {
    return this.adForm.controls.dateMin as FormControl;
  }

  get dateMax() {
    return this.adForm.controls.dateMax as FormControl;
  }

  get eve() {
    return this.adForm.controls.eve as FormControl;
  }

  get eventType() {
    return this.adForm.controls.eventType as FormControl;
  }

  get eventForm() {
    return this.adForm.get('eve');
  }

  displayFn(activity?: ResponseActivity): string | undefined {
    return activity ? activity.name : undefined;
  }

  private filterEvents(value: string | ResponseActivity) {
    let filterValue = '';
    if (value) {
      filterValue = typeof value === 'string' ? value.toLowerCase() : value.name.toLowerCase();
      return this.$allEvents.pipe(
        map(events => events.filter(event => event.name.toLowerCase().includes(filterValue)))
      );
    } else return this.$allEvents;
  }

  getEvents(mallId) {
    this.apiService.getEventByMall(mallId).subscribe((options: any) => {
      this.events = [];
      if (options.data === null) return;
      this.$allEvents = of(options.data);
      this.$filteredEvents = this.eventForm.valueChanges
        .pipe(
          startWith(''),
          switchMap(value => this.filterEvents(value))
        );
      for (const option of options.data) {
        const select = new SelectEvent();
        select.name = option.name;
        select.value = option.id;
        select.with_exoneration = option.with_exoneration;
        select.with_image = option.with_image;
        this.events.push(select);
      }
    });
    this.apiService.getEventTypes().subscribe((opt: any) => this.eventTypes = opt.data);
  }

  getMalls() {
    this.apiService.getListMall().subscribe(response => this.malls = response);
  }

  filter(name, dni, btnExport) {
    if (!this.showAdFilters) {
      btnExport ? this.exportExcel(name, dni) : this.getVisitants(name, dni);
    } else {
      if (this.adForm.get('dateMin').value) {
        let dateMin = (this.adForm.get('dateMin').value as Date);
        var dateStart = this.addA0(dateMin.getDate()) + '/' + this.addA0(dateMin.getMonth() + 1) + '/' + dateMin.getFullYear();
      }
      if (this.adForm.get('dateMax').value) {
        let dateMax = (this.adForm.get('dateMax').value as Date);
        var dateEnd = this.addA0(dateMax.getDate()) + '/' + this.addA0(dateMax.getMonth() + 1) + '/' + dateMax.getFullYear();
      }
      const mallId = this.adForm.get('selMall').value;
      const event = this.adForm.get('eve').value;
      const eventTypeId = this.adForm.get('eventType').value;
      btnExport ? this.exportExcel(name, dni, mallId, event, dateStart, dateEnd, eventTypeId) :
        this.getVisitants(name, dni, mallId, event ? event.id : null, dateStart, dateEnd, eventTypeId);
    }
  }

  getVisitants(nombre?: string, dni?: string, mallId?, event?, dateStart?, dateEnd?, eventTypeId?) {
    this.visilidad_spinner = true;
    this.visilidad_error = false;
    this.apiService.getAllVisitors(nombre, dni, mallId, event, dateStart, dateEnd, eventTypeId).subscribe(
      (data: Array<any>) => {
        if (data.length === 0) {
          this.visilidad_error = true;
          this.error_text = 'No se encontraron registros.';
          this.allVisitants = [];
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        } else {
          this.allVisitants = data;
          this.dataSource = new MatTableDataSource<any>(data);
        }
      },
      (error) => {
        this.visilidad_error = true;
        this.error_text = 'Error en el servidor, intente más tarde.';
        this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
      }).add(() => {
        this.visilidad_spinner = false;
        this.dataSource.paginator = this.paginator;
      });
  }

  addA0(number: number) {
    if (number < 10) return '0' + number;
    return number;
  }

  exportExcel(name?: string, dni?: string, mallId?, event?, dateStart?, dateEnd?, eventTypeId?) {
    this.visilidad_spinner = true;
    this.visilidad_error = false;
    const mall = this.malls.find(mall => mall.CodInmueble == mallId);
    const eventType = this.eventTypes.find(eventType => eventType.id == eventTypeId);
    this.apiService.exportExcel(name, dni, mall, event, dateStart, dateEnd, eventType).subscribe(response => {
      const blob = new Blob([response], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      let anchor = document.createElement("a");
      anchor.download = `Visitantes-${Date.now()}.xlsx`;
      anchor.href = url;
      anchor.click();
    }, (error) => {
      this.visilidad_error = true;
      this.error_text = 'Error en el servidor, intente más tarde.';
    }).add(() => this.visilidad_spinner = false);
  }

  disableButton() {
    return (typeof this.eve.value == 'string' && this.eve.value.length >= 1) || (this.name.trim() == '' && this.dni.trim() == ''
      && this.selMall.value == null && this.eventType.value == null && this.dateMin.value == null && this.dateMax.value == null)
      || this.visilidad_spinner;
  }
}
