import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';
import {Select} from '../../../models/select';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.styl']
})
export class SelectComponent implements OnChanges, OnInit {

  results: Object;
  searchValue = '';
  @Input() selected_value: any;
  @Input() options: Select[];
  @Input() isDisabled = false;
  @Input() isSearchable = true;
  @Input() placeholder: String = 'Seleccionar';
  @Output() selected = new EventEmitter<number>();
  @ViewChild('focus') input_focus: ElementRef;

  modelSelected = '2';
  constructor() {
  }

  ngOnInit() {
    // this.results = this.options;
    // if(this.options){
    //   this.selected_default = this.options[0].value;
    //   this.selected.emit(this.selected_default);
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    const options_i: SimpleChange = changes.options;
    if (options_i) {
      this.results = options_i.currentValue;
      this.modelSelected = '1';
    }
  }

  search(input) {
    this.results = this.options.filter(function (el) {
      return el.name.toLowerCase().indexOf(input.toLowerCase()) > -1;
    });
  }

  selectionChange(value) {
    this.results = this.options;
    this.searchValue = '';
    this.selected.emit(value);
  }

  clearInput() {
    this.input_focus.nativeElement.focus();
    this.searchValue = '';
    this.results = this.options;
  }
}
