import {Component, OnInit, NgModule, ViewChild, ChangeDetectorRef, ViewChildren} from '@angular/core';
import {MessagesService} from '../../services/messages.service';
import {ApiService} from '../../services/api.service';
import {Select} from '../../models/select';
import {SelectEvent} from '../../models/selectEvent';
import {HttpErrorResponse} from '@angular/common/http';
import {Visitor} from '../../models/responseVisitor';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatSelect} from '@angular/material';
import {take, takeUntil, first} from 'rxjs/operators';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from 'ngx-custom-validators';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ReplaySubject, Subject, of} from 'rxjs';
import {countryData} from './../../models/ContryData';
import {SelectUbigeo} from './../../models/selectUbigeo';

function validateDate(c: FormControl) {

  let isValid = null;
  if (c.value == null || c.value == undefined || c.value == '') {
    isValid = true;
  } else {
    if (!moment(c.value, 'DD-MM-YYYY').isValid()) {
      isValid = null;
    } else {
      isValid = true;
      const fechaInitMoment = moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
      const FechaCompare = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (fechaInitMoment >= FechaCompare) {
        isValid = null;
      } else {
        isValid = true;
      }
    }
  }

  return isValid ? null : {
    validateDate: {
      valid: false
    }
  };

}

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.styl'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ],
})

export class UsersComponent implements OnInit {
  mask = {guide: false, showMask: false, mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]};
  visilidad_formulario: boolean;
  visilidad_spinner: boolean;
  data_user: any;
  exonerador: boolean;
  imager: boolean;
  visitorForm: FormGroup;
  mall_id: FormControl;
  event_id: FormControl;
  document: FormControl;
  gender: FormControl;
  firstName: FormControl;
  last_name: FormControl;
  birthdate: FormControl;
  document_type: FormControl;
  nationality: FormControl;
  department: FormControl;
  province: FormControl;
  district: FormControl;
  email: FormControl;
  cellPhone: FormControl;
  agree_terms: FormControl;
  agree_exonerado: FormControl;
  agree_image: FormControl;
  p_agree_terms: boolean;
  maxFecha: Date = new Date();
  info_route = {
    breadcrumb_level: 2,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Visitor',
    breadcrumb_subtitle: 'Register'
  };
  options_gender: Select[];
  options_event: Select[];
  options_document_type: Select[];
  options_country: any = [];
  options_department: SelectUbigeo[];
  options_province: SelectUbigeo[] = [];
  options_district: SelectUbigeo[] = [];
  isDisabled = true;
  isNewVisitor = true;
  modelForm: Visitor = new Visitor();
  modelFormErrors: any = {};
  openModal: NgbActiveModal;

  validate_form = false;

  public eventCtrl: FormControl = new FormControl();
  public eventFilterCtrl: FormControl = new FormControl();
  private events: Select[] = [{value: '-1', name: ''}];
  private _onDestroy = new Subject<void>();
  public filteredEvents: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);

  @ViewChild('singleSelect') singleSelect: MatSelect;
  padOptions = {
    canvasWidth: '300',
    canvasHeight: '400',
    penColor: '#333',
    backgroundColor: '#fff'
  };

  _signature: any = null;
  signCheck = false;

  constructor(
    private messagesService: MessagesService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private changeDetector: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.getEvents();
    this.getGender();
    this.getCountry();
    this.getDocumentType();
    this.getDepartment();
    this.visilidad_formulario = false;
    this.visilidad_spinner = false;

    // load the initial event list
    this.filteredEvents.next(this.events.slice());
    this.eventFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEvents();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private setInitialValue() {
    this.filteredEvents
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: Select, b: Select) => a.value === b.value;
      });
  }

  private filterEvents() {
    if (!this.events) {
      return;
    }
    // get the search keyword
    let search = this.eventFilterCtrl.value;
    if (!search) {
      this.filteredEvents.next(this.events.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the events
    this.filteredEvents.next(
      this.events.filter(event => event.name.toLowerCase().indexOf(search) > -1)
    );
  }

  createFormControls() {
    this.mall_id = new FormControl(null);
    this.event_id = new FormControl('', Validators.required);
    this.document = new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(12),
      Validators.pattern('^[a-zA-Z0-9]+$')
    ]);
    this.firstName = new FormControl({value: null, disabled: true}, Validators.required);
    this.last_name = new FormControl({value: null, disabled: true}, Validators.required);
    this.birthdate = new FormControl({value: null, disabled: true},
      [
        Validators.minLength(10),
        validateDate
      ]);
    this.gender = new FormControl({value: null, disabled: true}, Validators.required);

    this.document_type = new FormControl({value: null, disabled: true}, Validators.required);
    this.nationality = new FormControl({value: null, disabled: true}, Validators.required);
    this.department = new FormControl({value: null, disabled: true}, Validators.required);
    this.province = new FormControl({value: null, disabled: true});
    this.district = new FormControl({value: null, disabled: true});

    this.cellPhone = new FormControl({value: null, disabled: true}, [
      CustomValidators.number
    ]);
    this.email = new FormControl({value: null, disabled: true}, [
      Validators.required,
      CustomValidators.email
    ]);
    this.agree_terms = new FormControl({value: false, disabled: true});
    this.agree_exonerado = new FormControl({value: false, disabled: true});
    this.agree_image = new FormControl({value: false, disabled: true});
  }

  createForm() {
    this.visitorForm = new FormGroup({
      mall_id: this.mall_id,
      event_id: this.event_id,
      document: this.document,
      firstName: this.firstName,
      last_name: this.last_name,
      birthdate: this.birthdate,
      document_type: this.document_type,
      nationality: this.nationality,
      department: this.department,
      province: this.province,
      district: this.district,
      gender: this.gender,
      cellPhone: this.cellPhone,
      email: this.email,
      agree_terms: this.agree_terms,
      agree_exonerado: this.agree_exonerado,
      agree_image: this.agree_image,
    });
  }

  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  getGender() {
    this.apiService.getListGender().subscribe((options: any) => {
        this.options_gender = [];
        for (const option of options.data) {
          const select = new Select();
          select.name = option.name;
          select.value = option.id;
          this.options_gender.push(select);
        }
      }
    );
  }

  getDocumentType() {
    this.apiService.getListDocumentType().subscribe((options: any) => {
        this.options_document_type = [];
        for (const option of options.data) {
          const select = new Select();
          select.name = option.description;
          select.value = option.id;
          this.options_document_type.push(select);
        }
      }
    );
  }

  getDepartment() {
    this.apiService.getListDepartment().subscribe((options: any) => {
        this.options_department = [];
        for (const option of options.data) {
          const select = new SelectUbigeo();
          select.name = option.name;
          select.value = option.id;
          select.code = option.code;

          this.options_department.push(select);
        }
      }
    );

    this.options_district = [];
    const select = new SelectUbigeo();
    select.name = 'OTRO';
    select.code = '00';
    select.value = '000000';
    this.options_district.push(select);
  }

  getProvince(code: any, codeProvince: any, codeDistrict: any) {
    let idProvince: any;
    this.apiService.getListProvince(code).subscribe(async (options: any) => {
        this.options_province = [];
        const select = new SelectUbigeo();
        select.name = 'OTRO';
        select.code = '0000';
        select.value = 0;
        this.options_province.push(select);
        if (codeProvince != null) {
          for (const option of options.data) {
            const select = new SelectUbigeo();
            select.name = option.name;
            select.value = option.id;
            select.code = option.code;
            if (option.code.substring(0, 4) == codeProvince) {
              idProvince = option.id;
              this.visitorForm.controls['province'].reset({value: idProvince, disabled: false});
              this.getDistrict(idProvince, codeDistrict);
            }
            this.options_province.push(select);
          }
          if (codeProvince.substring(2, 4) == '00') {
            idProvince = 1;
            this.visitorForm.controls['province'].reset({value: 0, disabled: false});
            this.getDistrict(idProvince, codeDistrict);
          }
        } else {
          for (const option of options.data) {
            const select = new SelectUbigeo();
            select.name = option.name;
            select.value = option.id;
            select.code = option.code;
            this.options_province.push(select);
          }

        }
      }
    );
  }

  getDistrict(code: any, codeDistrict: any) {
    this.apiService.getListDistrict(code).subscribe((options: any) => {
        this.options_district = [];
        const select = new SelectUbigeo();
        select.name = 'OTRO';
        select.code = '00';
        select.value = '000000';
        this.options_district.push(select);
        for (const option of options.data) {
          const select = new SelectUbigeo();
          select.name = option.name;
          select.code = option.id;
          select.value = option.code;
          this.options_district.push(select);
          if (codeDistrict != null) {
            this.visitorForm.controls['district'].reset({value: codeDistrict, disabled: false});
          }
        }
        if (codeDistrict.substring(4, 6) == '00') {
          this.visitorForm.controls['district'].reset({value: '000000', disabled: false});
        }
      }
    );
  }

  getCountry() {
    this.options_country = of(countryData);
  }


  getEvents() {
    this.apiService.getListEvent().subscribe((options: any) => {
        this.options_event = [];
        this.events = [];
        if (options.data === null) {
          this.messagesService.error('No hay eventos activos para este Mall.');
          return;
        }

        for (const option of options.data) {
          if (option.type != 'Especiales') {
            const select = new SelectEvent();
            select.name = option.name;
            select.value = option.id;
            select.with_exoneration = option.with_exoneration;
            select.with_image = option.with_image;
            this.options_event.push(select);
            this.events.push(select);
          }
        }
      }
    );
  }


  async onClickSearchClient() {
    if (this.visitorForm.invalid) {
      // tslint:disable-next-line:forin
      for (const i in this.visitorForm.controls) {
        this.visitorForm.controls[i].markAsDirty();
        this.visitorForm.controls[i].updateValueAndValidity();
      }
      this.validate_form = true;
      return;
    }

    this.validate_form = false;

    if (this.visitorForm.value.document.errors) {
      this.visitorForm.value.document.markAsTouched({onlySelf: true});
      return;
    }
    this.visitorForm.controls['document_type'].reset({value: 1, disabled: false});
    this.visitorForm.controls['nationality'].reset({value: 'PE', disabled: false});
    this.data_user = ApiService.getDataUser();
    this.apiService.getVisitorSearchByDni(this.visitorForm.value.document).subscribe(async (_response) => {
        this.visilidad_spinner = true;

        const response: any = _response;

        if (response.data) {
          let birthday;
          if (response.data.birthday == null) {
            birthday = null;
          } else {
            birthday = response.data.birthday;
            birthday = moment(birthday, 'YYYY-MM-DD').format('DD-MM-YYYY');
          }
          this.visitorForm.controls['firstName'].reset({value: response.data.first_name, disabled: false});
          this.visitorForm.controls['last_name'].reset({value: response.data.last_name, disabled: false});
          this.visitorForm.controls['email'].reset({value: response.data.email, disabled: false});
          this.visitorForm.controls['cellPhone'].reset({value: response.data.phone, disabled: false});
          this.visitorForm.controls['gender'].reset({value: response.data.gender.id, disabled: false});
          this.visitorForm.controls['birthdate'].reset({value: birthday, disabled: false});
          this.visitorForm.controls['agree_exonerado'].reset({value: false, disabled: false});
          this.visitorForm.controls['agree_image'].reset({value: false, disabled: false});
          this.isNewVisitor = false;
          //   this.visitorForm.controls['agree_terms'].reset({value: true, disabled: true});
          if (response.data.agree_terms == 1) {
            this.visitorForm.controls['agree_terms'].reset({value: true, disabled: true});
            this.p_agree_terms = true;
            //Here I should lock the value as false, si no firmo previamente
          } else {
            this.visitorForm.controls['agree_terms'].reset({value: false, disabled: false});
            this.p_agree_terms = false;
          }
          this.visitorForm.controls['document_type'].reset({value: response.data.document_type, disabled: false});
          this.visitorForm.controls['nationality'].reset({value: response.data.nationality, disabled: false});
          if (response.data.cod_ubigeo) {
            await this.asingarUbigeo(response);
          } else {
            const codUbigeo: string = this.data_user.codUbigeo;
            if (codUbigeo == null || codUbigeo == undefined) {
              this.visitorForm.controls['department'].reset({value: '', disabled: false});
              this.visitorForm.controls['province'].reset({value: '', disabled: false});
              this.visitorForm.controls['district'].reset({value: '', disabled: false});
            } else {
              const departmentCod = parseInt(codUbigeo.substring(0, 2));
              this.visitorForm.controls['department'].reset({value: departmentCod, disabled: false});
              this.getProvince(departmentCod, null, null);
              this.visitorForm.controls['province'].reset({value: '', disabled: false});
              this.visitorForm.controls['district'].reset({value: '', disabled: false});
            }
          }
          this.visilidad_spinner = false;
          this.visilidad_formulario = true;
          this.messagesService.warning('El visitante con ese DNI ya se encuentra registrado.');
        } else {
          this.obtenerVisitantePorApiExterna();

        }
        this.signature = null;
        this.isDisabled = false;
      },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }

  asingarUbigeo(response: any) {
    const codUbigeo = response.data.cod_ubigeo;
    const departmentCod = parseInt(codUbigeo.substring(0, 2));

    this.getProvince(departmentCod, codUbigeo.substring(0, 4), codUbigeo);
    this.visitorForm.controls['department'].reset({value: departmentCod, disabled: false});

  }

  obtenerVisitantePorApiExterna() {
    this.apiService.getVisitorSearchByDniExterno(this.visitorForm.value.document).subscribe((_response) => {
        const response2: any = _response;
        if (response2.data) {
          this.visitorForm.controls['firstName'].reset({value: response2.data.first_name, disabled: false});
          this.visitorForm.controls['last_name'].reset({value: response2.data.last_name, disabled: false});
          this.visitorForm.controls['email'].reset({value: response2.data.email, disabled: false});
          this.visitorForm.controls['cellPhone'].reset({value: response2.data.phone, disabled: false});
          this.visitorForm.controls['gender'].reset({value: response2.data.gender, disabled: false});
          this.visitorForm.controls['birthdate'].reset({value: null, disabled: false});
          this.visitorForm.controls['agree_terms'].reset({value: false, disabled: false});
          this.visitorForm.controls['agree_exonerado'].reset({value: false, disabled: false});
          this.visitorForm.controls['agree_image'].reset({value: false, disabled: false});
          this.visitorForm.controls['department'].reset({value: null, disabled: false});
          this.visitorForm.controls['province'].reset({value: null, disabled: false});
          this.visitorForm.controls['district'].reset({value: null, disabled: false});

          this.visilidad_spinner = false;
          this.visilidad_formulario = true;
          this.messagesService.success('El DNI ha sido encontrado.');
        } else {
          const document = this.visitorForm.value.document;
          const mall_id: string = this.modelForm.mall_id;
          const event_id = this.modelForm.event_id;
          this.modelForm = new Visitor();
          this.modelForm.mall_id = mall_id;
          this.modelForm.event_id = event_id;
          this.modelForm.document = document;

          this.visitorForm.controls['firstName'].reset({value: null, disabled: false});
          this.visitorForm.controls['last_name'].reset({value: null, disabled: false});
          this.visitorForm.controls['email'].reset({value: null, disabled: false});
          this.visitorForm.controls['cellPhone'].reset({value: null, disabled: false});
          this.visitorForm.controls['gender'].reset({value: null, disabled: false});
          this.visitorForm.controls['birthdate'].reset({value: null, disabled: false});
          this.visitorForm.controls['agree_terms'].reset({value: false, disabled: false});
          this.visitorForm.controls['agree_exonerado'].reset({value: false, disabled: false});
          this.visitorForm.controls['agree_image'].reset({value: false, disabled: false});
          const codUbigeo: string = this.data_user.codUbigeo;
          if (codUbigeo == null || codUbigeo == undefined) {
            this.visitorForm.controls['department'].reset({value: '', disabled: false});
            this.visitorForm.controls['province'].reset({value: '', disabled: false});
            this.visitorForm.controls['district'].reset({value: '', disabled: false});
          } else {
            const departmentCod = parseInt(codUbigeo.substring(0, 2));
            this.visitorForm.controls['department'].reset({value: departmentCod, disabled: false});
            this.getProvince(departmentCod, null, null);
            this.visitorForm.controls['province'].reset({value: 0, disabled: false});
            this.visitorForm.controls['district'].reset({value: '000000', disabled: false});
          }
          this.visilidad_spinner = false;
          this.visilidad_formulario = true;
          this.messagesService.warning('El DNI no se encuentra registrado.');

        }
        this.isNewVisitor = true;
      },
      (errorResponse: HttpErrorResponse) => {
        const document = this.visitorForm.value.document;
        const mall_id: string = this.modelForm.mall_id;
        const event_id = this.modelForm.event_id;
        this.modelForm = new Visitor();
        this.modelForm.mall_id = mall_id;
        this.modelForm.event_id = event_id;
        this.modelForm.document = document;
        this.isNewVisitor = true;

        this.visitorForm.controls['firstName'].reset({value: null, disabled: false});
        this.visitorForm.controls['last_name'].reset({value: null, disabled: false});
        this.visitorForm.controls['email'].reset({value: null, disabled: false});
        this.visitorForm.controls['cellPhone'].reset({value: null, disabled: false});
        this.visitorForm.controls['gender'].reset({value: null, disabled: false});
        this.visitorForm.controls['birthdate'].reset({value: null, disabled: false});
        this.visitorForm.controls['agree_terms'].reset({value: false, disabled: false});
        this.visitorForm.controls['agree_exonerado'].reset({value: false, disabled: false});
        this.visitorForm.controls['agree_image'].reset({value: false, disabled: false});
        const codUbigeo: string = this.data_user.codUbigeo;
        if (codUbigeo == null || codUbigeo == undefined) {
          this.visitorForm.controls['department'].reset({value: '', disabled: false});
          this.visitorForm.controls['province'].reset({value: '', disabled: false});
          this.visitorForm.controls['district'].reset({value: '', disabled: false});
        } else {
          const departmentCod = parseInt(codUbigeo.substring(0, 2));
          this.visitorForm.controls['department'].reset({value: departmentCod, disabled: false});
          this.getProvince(departmentCod, null, null);
          this.visitorForm.controls['province'].reset({value: 0, disabled: false});
          this.visitorForm.controls['district'].reset({value: '000000', disabled: false});
        }

        this.visilidad_spinner = false;
        this.visilidad_formulario = true;
        this.messagesService.warning('El DNI no se encuentra registrado.');
      });
  }

  onSelectedEvent(value: any) {
    this.options_event.forEach((event: any) => {
      if (event.value === value.value) {
        this.exonerador = event.with_exoneration === 1;
        this.imager = event.with_image === 1;
      }
    });
    this.modelForm.event_id = value.value;
  }

  onSelectedDeparment(value: any) {
    this.getProvince(value, null, null);
  }

  onSelectedProvince(value: any) {
    this.getDistrict(value, null);
    this.options_district = [];
  }

  onSelectedDocumentType(value: any) {

    if (value == 1) {
      this.visitorForm.controls['nationality'].reset({value: 'PE', disabled: false});
    }
  }


  onSelectedGender(value: any) {
    this.modelForm.gender = value;
  }

  onSubmit() {
    const sign = this.signature;
    this.isDisabled = true;
    if (!this.visitorForm.valid || sign === null) {
      this.validateForm(this.visitorForm);
      this.isDisabled = false;
      return;
    }
    this.modelForm = {...this.visitorForm.value, userSign: sign};
    this.modelForm.event_id = this.visitorForm.value.event_id.value;
    this.modelForm.mall_id = this.data_user.mallId;
    if (this.visitorForm.value.district == '000000') {
      let department = this.visitorForm.value.department;
      department = department > 0 ? department : '0' + department;
      let province = this.visitorForm.value.province;
      province = province > 0 ? province : '0' + province;
      let district = this.visitorForm.value.district;
      district = district == '000000' ? '00' : district.substring(0, 2);
      this.modelForm.cod_ubigeo = department + province + district;
    } else {
      this.modelForm.cod_ubigeo = this.visitorForm.value.district;
    }
    if (!(this.modelForm.birthdate == null) && (this.modelForm.birthdate.length > 0)) {
      this.modelForm.birthdate = moment(this.modelForm.birthdate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    } else {
      this.modelForm.birthdate = null;
    }

    if (this.isNewVisitor) {
      this.createVisitor(this.modelForm);
      this.visilidad_formulario = false;
    } else {
      if (this.p_agree_terms) {
        this.modelForm.agree_terms = true;
      }
      this.updateVisitor(this.modelForm);
      this.visilidad_formulario = false;
    }
    this.signature = null;
  }

  createVisitor(form) {
    this.visitorForm.controls['event_id'].disable();
    this.visitorForm.controls['document'].disable();
    this.messagesService.warning('Se está registrando los datos...');

    this.apiService.postVisitorInsert(form).pipe(first()).subscribe((response) => {

        if (form.agree_terms) {
          this.apiService.postEmailSend(form).subscribe((email) => {
          });
        }
        if (form.agree_image) {
          this.apiService.postEmailConsentimientoUsoImageSend(form).subscribe((email) => {
          });
        }

        this.clearForm();
        this.visitorForm.controls['event_id'].enable();
        this.visitorForm.controls['document'].enable();
        this.messagesService.success('Se registro correctamente');
      },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }

  updateVisitor(form) {
    this.visitorForm.controls['event_id'].disable();
    this.visitorForm.controls['document'].disable();
    this.messagesService.warning('Se está registrando los datos...');
    this.apiService.putVisitorInsert(form).pipe(first()).subscribe((response) => {

        if ((this.firstName.dirty && this.firstName.touched) || (this.last_name.dirty && this.last_name.touched) || (this.email.dirty && this.email.touched) || (this.agree_terms && this.agree_terms.touched)) {
          if (form.agree_terms) {
            this.apiService.postEmailSend(form).subscribe((email) => {
            });
          }
        }
        if (form.agree_image) {
          this.apiService.postEmailConsentimientoUsoImageSend(form).subscribe((email) => {
          });
        }
        this.clearForm();
        this.visitorForm.controls['event_id'].enable();
        this.visitorForm.controls['document'].enable();
        this.messagesService.success('Se registro correctamente');
      },
      (errorResponse: HttpErrorResponse) => {
        this.modelFormErrors = errorResponse.error.errors;
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });

  }

  clearForm() {
    const mall_id: string = this.modelForm.mall_id;
    const event_id = this.modelForm.event_id;
    this.modelForm = new Visitor();
    this.modelForm.mall_id = mall_id;
    this.modelForm.event_id = event_id;
    this.isDisabled = true;
    this.isNewVisitor = true;

    this.visitorForm.controls['document'].reset({value: null, disabled: false});
    this.visitorForm.controls['firstName'].reset({value: null, disabled: true});
    this.visitorForm.controls['last_name'].reset({value: null, disabled: true});
    this.visitorForm.controls['email'].reset({value: null, disabled: true});
    this.visitorForm.controls['cellPhone'].reset({value: null, disabled: true});
    this.visitorForm.controls['gender'].reset({value: null, disabled: true});
    this.visitorForm.controls['birthdate'].reset({value: null, disabled: true});
    this.visitorForm.controls['agree_terms'].reset({value: false, disabled: true});
    this.visitorForm.controls['agree_exonerado'].reset({value: false, disabled: true});
    this.visitorForm.controls['agree_image'].reset({value: false, disabled: true});
    this.visitorForm.controls['department'].reset({value: false, disabled: true});
    this.visitorForm.controls['province'].reset({value: false, disabled: true});
    this.visitorForm.controls['district'].reset({value: false, disabled: true});
  }

  openTerms(content) {
    this.openModal = this.modalService.open(content, {centered: true});
  }

  console(value: any) {
    console.log(value);
  }

  //This function resets the checkbox value to false unless the modal changes this
  openFromCheckbox(content, controlName?) {
    this.openModal = this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'signer__board',
      backdrop: 'static'
    });
  }

  //After signing
  doCloseTerms(signerPad?) {
    // if (controlName || this.visitorForm.controls[controlName].disabled===false){
    //   this.visitorForm.controls[controlName].reset({value:true})
    // }
    this.signature = signerPad.signaturePad.toDataURL();
    if (this.signCheck === false) { //minimun length when signer is blank, is 4042
      this.signature = null;
      this.messagesService.error('Debe firmar.');
    }
    this.openModal.dismiss();
    this.signCheck = false;
  }

  drawBegin() {
    this.signCheck = true;
  }

  public get signature() {
    return this._signature;
  }

  public set signature(value) {
    this._signature = value;
  }

  //The parameter here, is the component itself's ID
  drawComplete(signerPad): void {
    //This should go to the send button
    // this.signature = signerPad.signaturePad.toDataURL();
    // console.log("ooot", this._signature)
  }
} //We still gotta modify the submit and validate yo
