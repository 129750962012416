import { Component, OnInit } from '@angular/core';
import { MessagesService } from './../../../services/messages.service';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.styl']
})
export class MessagesComponent implements OnInit {

  message: any;

  constructor(private messagesService: MessagesService) { }

  ngOnInit() {
    this.messagesService.getMessage().subscribe(message => {
      this.message = message;
      setTimeout(() => this.message = null, 5000);
    });
  }

}
