import { Component, OnInit, Input } from '@angular/core';
import { InfoRoute } from './../../../models/inforoute';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.styl']
})
export class BreadcrumbComponent implements OnInit {

  @Input() info_route = new InfoRoute();

  constructor() { }

  ngOnInit() {
  }

}
