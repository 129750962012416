import {Component, OnInit, ViewChild} from '@angular/core';
import {MessagesService} from '../../../services/messages.service';
import {ApiService} from '../../../services/api.service';
import {Select} from '../../../models/select';
import {HttpErrorResponse} from '@angular/common/http';
import {ListEventMall} from '../../../models/listEventMall';
import {SpecifyDate} from '../../../models/specifyDate';
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatPaginator, MatTableDataSource, MatSort, MatSelect} from '@angular/material';
import {ListMallDate} from '../../../models/listMallDate';
import {EventAssing} from '../../../models/responseEventAssing';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';

function validateDate(c: FormControl) {

  let isValid = null;
  if (c.value == null || c.value == undefined || c.value == '') {
    isValid = true;
  } else {
    if (!moment(c.value, 'DD-MM-YYYY').isValid()) {
      isValid = null;
    } else {
      isValid = true;
      const fechaInitMoment = moment('1900-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD');
      const FechaCompare = moment(c.value, 'DD-MM-YYYY').format('YYYY-MM-DD');
      if (fechaInitMoment >= FechaCompare) {
        isValid = null;
      } else {
        isValid = true;
      }
    }
  }
  return isValid ? null : {
    validateDate: {
      valid: false
    }
  };

}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.styl'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'}
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MaintenanceComponent implements OnInit {
  mask = {
    guide: false,
    showMask: false,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };
  public eventCalls: Array<string> = [];
  p_event_name: string;
  estado: number;
  p_event_id;
  list_event: ListMallDate[];
  data_user: any;
  modalReference: any;
  closeResult: string;
  tipo_event_id: FormControl;
  modelForm: EventAssing = new EventAssing();
  mall_id: FormControl;
  eventMallForm: FormGroup;
  id_event: FormControl;
  date_start: FormControl;
  date_end: FormControl;
  with_exoneration: FormControl;
  with_image: FormControl;
  eventForm: FormGroup;
  event_name_update: FormControl;
  date_start_update: FormControl;
  date_end_update: FormControl;
  with_exoneration_update: FormControl;
  with_image_update: FormControl;
  eventFormUpdate: FormGroup;
  options_mall: Select[];
  options_tipo: Select[];
  options_event: Select[];
  options_mall_selected: Select[];
  nombreMall: string;
  animal: string;
  name: string;
  openModal: NgbActiveModal;
  displayedColumns: string[] = ['name', 'type'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild('singleSelect') singleSelect: MatSelect;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public bankCtrl: FormControl = new FormControl();
  public bankFilterCtrl: FormControl = new FormControl();
  private banks: Select[] = [{value: '-1', name: 'Escriba para buscar eventos'}];
  private _onDestroy = new Subject<void>();
  public filteredBanks: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);

  info_route = {
    breadcrumb_level: 2,
    breadcrumb_icon_name: 'bubble_chart',
    breadcrumb_title: 'Maintenance',
    breadcrumb_subtitle: 'Mantenimiento'
  };

  constructor(
    public dialog: MatDialog,
    private messagesService: MessagesService,
    private apiService: ApiService,
    private modalService: NgbModal) {
  }

  getTipos() {
    this.options_tipo = [];
    const select = new Select();
    select.name = 'Mall';
    select.value = 1;
    this.options_tipo.push(select);
    const select2 = new Select();
    select2.name = 'Transversal';
    select2.value = 2;
    this.options_tipo.push(select2);
  }

  getEvents() {
    this.apiService.getListarEventMall(this.data_user.mallId, 1).subscribe((response) => {
      const cadena = response.data;
      this.banks = [];
      let ctl = -1;
      cadena.forEach(element => {
        if (element.type == 'Mall') {
          const list = new Select();
          list.name = element.name;
          list.value = element.id;
          if (ctl == -1) {
            ctl = element.id;
            this.banks.push(list);
          }
          if (ctl == element.id) {
          } else {
            ctl = element.id;
            this.banks.push(list);
          }
        }
      });
      // const options: any = response.data;
      // for (const option of options) {
      //   const select = new Select();
      //   select.name = option.name;
      //   select.value = option.id;
      //   this.banks.push(select);
      // }
    });

  }


  openDelete(content, id: number, elementEvent: any) {
    this.p_event_id = id;
    this.p_event_name = elementEvent.name;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-delete',
      centered: true,
      size: 'sm',
      backdrop: 'static'
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  openLgUpdate(content, element: any, elementEvent: any) {

    this.p_event_name = elementEvent.name;
    if (element.with_exoneration == 'verified_user') {
      this.eventFormUpdate.controls['with_exoneration_update'].reset({
        value: true,
        disabled: false
      });
    } else {
      this.eventFormUpdate.controls['with_exoneration_update'].reset({
        value: false,
        disabled: false
      });
    }
    if (element.with_image == 'verified_user') {
      this.eventFormUpdate.controls['with_image_update'].reset({
        value: true,
        disabled: false
      });
    } else {
      this.eventFormUpdate.controls['with_image_update'].reset({
        value: false,
        disabled: false
      });
    }
    this.eventFormUpdate.controls['event_name_update'].reset({value: elementEvent.name, disabled: true});
    this.eventFormUpdate.controls['date_start_update'].reset({value: element.date_start, disabled: false});
    this.eventFormUpdate.controls['date_end_update'].reset({value: element.date_end, disabled: false});
    console.log(element.date_start);
    if (element.date_start == null) {
      this.eventFormUpdate.controls['lunes_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['martes_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['miercoles_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['jueves_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['viernes_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['sabado_update'].reset({value: true, disabled: false});
      this.eventFormUpdate.controls['domingo_update'].reset({value: true, disabled: false});
    } else {
      this.eventFormUpdate.controls['lunes_update'].reset({value: element.lunes, disabled: false});
      this.eventFormUpdate.controls['martes_update'].reset({value: element.martes, disabled: false});
      this.eventFormUpdate.controls['miercoles_update'].reset({value: element.miercoles, disabled: false});
      this.eventFormUpdate.controls['jueves_update'].reset({value: element.jueves, disabled: false});
      this.eventFormUpdate.controls['viernes_update'].reset({value: element.viernes, disabled: false});
      this.eventFormUpdate.controls['sabado_update'].reset({value: element.sabado, disabled: false});
      this.eventFormUpdate.controls['domingo_update'].reset({value: element.domingo, disabled: false});
    }
    this.p_event_id = element.id;
    this.modalReference = this.modalService.open(content, {
      windowClass: 'modal-tm',
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  openLg(content) {

    this.getEvents();
    this.eventForm.controls['id_event'].reset({value: null, disabled: false});
    this.eventForm.controls['with_exoneration'].reset({value: true, disabled: false});
    this.eventForm.controls['with_image'].reset({value: true, disabled: false});
    this.eventForm.controls['date_start'].reset({value: null, disabled: false});
    this.eventForm.controls['date_end'].reset({value: null, disabled: false});
    this.eventForm.controls['lunes'].reset({value: true, disabled: false});
    this.eventForm.controls['martes'].reset({value: true, disabled: false});
    this.eventForm.controls['miercoles'].reset({value: true, disabled: false});
    this.eventForm.controls['jueves'].reset({value: true, disabled: false});
    this.eventForm.controls['viernes'].reset({value: true, disabled: false});
    this.eventForm.controls['sabado'].reset({value: true, disabled: false});
    this.eventForm.controls['domingo'].reset({value: true, disabled: false});
    this.modalReference = this.modalService.open(content, {centered: true, size: 'lg', backdrop: 'static'});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createForm() {
    this.eventForm = new FormGroup({
      id_event: this.id_event,
      date_start: this.date_start,
      date_end: this.date_end,
      with_exoneration: this.with_exoneration,
      with_image: this.with_image,
      lunes: new FormControl(null, []),
      martes: new FormControl(null, []),
      miercoles: new FormControl(null, []),
      jueves: new FormControl(null, []),
      viernes: new FormControl(null, []),
      sabado: new FormControl(null, []),
      domingo: new FormControl(null, []),
    });
    this.eventFormUpdate = new FormGroup({
      date_start_update: this.date_start_update,
      date_end_update: this.date_end_update,
      event_name_update: this.event_name_update,
      with_exoneration_update: this.with_exoneration_update,
      with_image_update: this.with_image_update,
      lunes_update: new FormControl(null, []),
      martes_update: new FormControl(null, []),
      miercoles_update: new FormControl(null, []),
      jueves_update: new FormControl(null, []),
      viernes_update: new FormControl(null, []),
      sabado_update: new FormControl(null, []),
      domingo_update: new FormControl(null, []),
    });
  }

  createFormControls() {
    this.mall_id = new FormControl(null, [
      Validators.required
    ]);
    this.tipo_event_id = new FormControl(null, [
      Validators.required
    ]);
    // Form Assing
    this.id_event = new FormControl(null, [
      Validators.required
    ]);
    this.date_start = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.date_end = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.with_exoneration = new FormControl(null, []);
    this.with_image = new FormControl(null, []);
    // Form create assing
    this.event_name_update = new FormControl(null, [
      Validators.required
    ]);
    this.date_start_update = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.date_end_update = new FormControl(null, [
      Validators.required,
      Validators.minLength(10),
      validateDate
    ]);
    this.with_exoneration_update = new FormControl(null, []);
    this.with_image_update = new FormControl(null, []);
    // Form update assing
  }

  validateForm(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }

  onClickDeleteAssing() {
    this.apiService.deleteAssingEvent(this.p_event_id, 1).subscribe((response) => {
        this.messagesService.success('El evento fue eliminado con exito.');
        this.modalReference.close();
        this.listarEventos();
      },
      (errorResponse: HttpErrorResponse) => {
        this.modalReference.close();
        this.messagesService.error('Ocurrió un error al eliminar el evento, por favor intente de nuevo.');
      });
  }

  onClickReasignarEvent() {
    if (!this.eventFormUpdate.valid) {
      this.validateForm(this.eventFormUpdate);
      return;
    }

    this.modelForm.id_event = 1;
    this.modelForm.id_mall = this.p_event_id;
    this.modelForm.date_start = this.eventFormUpdate.value.date_start_update;
    this.modelForm.date_end = this.eventFormUpdate.value.date_end_update;

    if (this.eventFormUpdate.value.with_exoneration_update == 1) {
      this.modelForm.with_exoneration = true;
    } else {
      this.modelForm.with_exoneration = false;
    }
    if (this.eventFormUpdate.value.with_image_update == 1) {
      this.modelForm.with_image = true;
    } else {
      this.modelForm.with_image = false;
    }

    this.modelForm.lunes = this.eventFormUpdate.value.lunes_update;
    this.modelForm.martes = this.eventFormUpdate.value.martes_update;
    this.modelForm.miercoles = this.eventFormUpdate.value.miercoles_update;
    this.modelForm.jueves = this.eventFormUpdate.value.jueves_update;
    this.modelForm.viernes = this.eventFormUpdate.value.viernes_update;
    this.modelForm.sabado = this.eventFormUpdate.value.sabado_update;
    this.modelForm.domingo = this.eventFormUpdate.value.domingo_update;
    this.modelForm.date_start = moment(this.modelForm.date_start, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.modelForm.date_end = moment(this.modelForm.date_end, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.apiService.putEventAssing(this.modelForm).subscribe((response) => {
        this.listarEventos();
        this.modalReference.close();
        this.messagesService.success('La asignación  de evento se modifico con exito.');
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });
  }

  onClickAsignarEvent() {
    if (!this.eventForm.valid) {
      this.validateForm(this.eventForm);
      return;
    }


    this.modelForm = this.eventForm.value;
    this.modelForm.id_mall = this.data_user.mallId;
    this.modelForm.date_start = moment(this.modelForm.date_start, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.modelForm.date_end = moment(this.modelForm.date_end, 'DD/MM/YYYY').format('YYYY-MM-DD');
    this.options_mall_selected = [{'value': this.data_user.mallId, 'name': 'name'}];
    let date_weekend = [];
    date_weekend.push(this.modelForm.lunes);
    date_weekend.push(this.modelForm.martes);
    date_weekend.push(this.modelForm.miercoles);
    date_weekend.push(this.modelForm.jueves);
    date_weekend.push(this.modelForm.viernes);
    date_weekend.push(this.modelForm.sabado);
    date_weekend.push(this.modelForm.domingo);

    const row = {
      id: 1, date_start: this.modelForm.date_start, date_end: this.modelForm.date_end,
      date_weekend: date_weekend, with_exoneration_boolean: this.modelForm.with_exoneration,
      with_image_boolean: this.modelForm.with_image
    };
    DATE_DATA.push(row);

    this.apiService.postEventAssing(this.eventForm.value.id_event.value, this.options_mall_selected, DATE_DATA
    ).subscribe((response) => {
        this.modalReference.close();
        this.messagesService.success('El evento se asigno con exito.');
        this.listarEventos();
        this.clearForm();

      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al procesar los datos enviados, por favor intente una vez más.');
      });

  }


  clearForm() {
    this.options_mall_selected = [];
    DATE_DATA = [];
    this.eventForm.reset();
  }

  listarEventos() {
    const id = this.data_user.mallId;
    this.apiService.getListarEventMall(id, 1).subscribe((response) => {
        const data = response.data;
        this.list_event = [];
        let id_event = -1;
        let name_event;
        let type_event;
        let detailsId;
        let list;
        const final = data.length;
        let contador = 0;
        data.forEach(element => {
          let fechaInitMoment = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
          let FechaCompare = moment(element.date_end, 'YYYY-MM-DD').format('YYYY-MM-DD');
          contador = contador + 1;
          if (id_event == -1) {
            list = new ListEventMall();
            list.specifyDate = [];
            id_event = element.id;
            name_event = element.name;
            type_event = element.type;
            detailsId = element.detail_id;
            if ('Mall' === type_event) {
              list.typeSimbol = 'location_city';
            } else {
              list.typeSimbol = 'domain';
            }
          }
          if (id_event == element.id) {
            const dateRow = new SpecifyDate();
            dateRow.id = element.detail_id;
            if (element.date_start === null || element.date_end === null) {
              dateRow.color = 'warn';
              dateRow.colorRow = '#C9D8DA';
              dateRow.date_start = element.date_start;
              dateRow.date_end = element.date_end;
              dateRow.ico_state = 'add_alert';
              dateRow.ico_state_msg = 'Asignación pendiente de fecha';
            } else {
              dateRow.date_start = moment(element.date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
              dateRow.date_end = moment(element.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            if ('Mall' === type_event) {
              dateRow.display = 'visible';

            } else {
              dateRow.display = 'hidden';
            }

            dateRow.with_exoneration_boolean = element.with_exoneration === '1' ? true : false;
            if (element.with_exoneration) {
              dateRow.with_exoneration = 'verified_user';
              dateRow.with_exoneration_msg = 'Con exoneración de responsabilidades';
            } else {
              dateRow.with_exoneration = 'report';
              dateRow.with_exoneration_msg = 'Sin exoneración de responsabilidades';
            }
            dateRow.with_image_boolean = element.with_image === '1' ? true : false;
            if (element.with_image) {
              dateRow.with_image = 'verified_user';
              dateRow.with_image_msg = 'Con consentimiento de uso de imagen';
            } else {
              dateRow.with_image = 'report';
              dateRow.with_image_msg = 'Sin consentimiento de uso de imagen';
            }
            dateRow.lunes = element.lunes == 'true' ? true : false;
            dateRow.martes = element.martes == 'true' ? true : false;
            dateRow.miercoles = element.miercoles == 'true' ? true : false;
            dateRow.jueves = element.jueves == 'true' ? true : false;
            dateRow.viernes = element.viernes == 'true' ? true : false;
            dateRow.sabado = element.sabado == 'true' ? true : false;
            dateRow.domingo = element.domingo == 'true' ? true : false;


            if (this.estado == 1) {
              if (fechaInitMoment <= FechaCompare) {
                dateRow.color = 'primary';
                list.color = 'primary';
              } else {
                list.color = 'warn';
                dateRow.color = 'warn';
                dateRow.ico_state = 'warning';
                dateRow.ico_state_msg = 'Asignación culminada';
                dateRow.colorRow = '#ff000';
              }
              list.specifyDate.push(dateRow);
              if (final <= contador) {
                this.list_event.push(list);
              }
            } else {
              if (fechaInitMoment <= FechaCompare) {
                list.color = 'primary';
                dateRow.color = 'primary';
                list.specifyDate.push(dateRow);
                if (final <= contador) {
                  this.list_event.push(list);
                }
              }
            }
          } else {
            list.id = detailsId;
            list.name = name_event;
            list.type = type_event;
            detailsId = element.detail_id;

            if ('Mall' === type_event) {
              list.typeSimbol = 'location_city';
            } else {
              list.typeSimbol = 'domain';
            }

            if (list.specifyDate.length > 0) {
              this.list_event.push(list);
            }
            list = new ListEventMall();
            list.specifyDate = [];
            id_event = element.id;
            detailsId = element.detail_id;
            name_event = element.name;
            type_event = element.type;
            list.id = detailsId;
            list.name = name_event;
            list.type = type_event;
            if ('Mall' === type_event) {
              list.typeSimbol = 'location_city';
            } else {
              list.typeSimbol = 'domain';
            }
            //Men
            const dateRow = new SpecifyDate();
            dateRow.id = element.detail_id;

            if (element.date_start === null || element.date_end === null) {
              dateRow.color = 'warn';
              dateRow.colorRow = '#C9D8DA';
              dateRow.date_start = element.date_start;
              dateRow.date_end = element.date_end;
              dateRow.ico_state = 'add_alert';
              dateRow.ico_state_msg = 'Asignación pendiente de fecha';
            } else {
              dateRow.date_start = moment(element.date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
              dateRow.date_end = moment(element.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
            }
            if ('Mall' === type_event) {
              dateRow.display = 'visible';

            } else {
              dateRow.display = 'hidden';
            }

            dateRow.with_exoneration_boolean = element.with_exoneration === '1' ? true : false;
            if (element.with_exoneration) {
              dateRow.with_exoneration = 'verified_user';
              dateRow.with_exoneration_msg = 'Con exoneración de responsabilidades';
            } else {
              dateRow.with_exoneration = 'report';
              dateRow.with_exoneration_msg = 'Sin exoneración de responsabilidades';
            }
            dateRow.with_image_boolean = element.with_image === '1' ? true : false;
            if (element.with_image) {
              dateRow.with_image = 'verified_user';
              dateRow.with_image_msg = 'Con consentimiento de uso de imagen';
            } else {
              dateRow.with_image = 'report';
              dateRow.with_image_msg = 'Sin consentimiento de uso de imagen';
            }
            dateRow.lunes = element.lunes == 'true' ? true : false;
            dateRow.martes = element.martes == 'true' ? true : false;
            dateRow.miercoles = element.miercoles == 'true' ? true : false;
            dateRow.jueves = element.jueves == 'true' ? true : false;
            dateRow.viernes = element.viernes == 'true' ? true : false;
            dateRow.sabado = element.sabado == 'true' ? true : false;
            dateRow.domingo = element.domingo == 'true' ? true : false;


            if (this.estado == 1) {
              if (fechaInitMoment <= FechaCompare) {
                dateRow.color = 'primary';
                list.color = 'primary';
              } else {
                list.color = 'warn';
                dateRow.color = 'warn';
                dateRow.colorRow = '#ff000';
                dateRow.ico_state = 'warning';
                dateRow.ico_state_msg = 'Asignación culminada';
              }
              list.specifyDate.push(dateRow);
              if (final <= contador) {
                this.list_event.push(list);
              }
            } else {
              if (fechaInitMoment <= FechaCompare) {
                list.color = 'primary';
                dateRow.color = 'primary';
                list.specifyDate.push(dateRow);
                if (final <= contador) {
                  this.list_event.push(list);
                }
              }
            }

          }

        });

        ELEMENT_DATA = this.list_event;
        this.dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      (errorResponse: HttpErrorResponse) => {
        this.messagesService.error('Ocurrió un error al obtener los datos, por favor actualize la pagina.');
      });

  }

  testChange(event) {
    if (event.checked) {
      this.estado = 1;
    } else {
      this.estado = 0;
    }
    this.listarEventos();
  }

  last10Calls() {
    const end = this.eventCalls.length;
    return this.eventCalls.slice(Math.max(0, end - 10), end);
  }

  configInit() {
    this.data_user = ApiService.getDataUser();
    this.estado = 0;
  }


  ngOnInit() {

    this.getTipos();
    this.createFormControls();
    this.createForm();
    this.configInit();
    this.listarEventos();
    this.getEvents();
    this.filteredBanks.next(this.banks.slice());
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
    this.clearForm();
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }


  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


}


export interface PeriodicElement {

  position: number;
  name: string;
  typeString: string;
  typeSimbol: string;
  date_start: string;
  date_end: string;
  with_exoneration: string;
  with_exoneration_boolean: boolean;
  with_exoneration_msg: string;
  with_image: string;
  with_image_boolean: boolean;
  with_image_msg: string;
  id: number;
  color: string;
}

export interface SpecifyDateAdd {
  id: number;
  date_start: string;
  date_end: string;
  date_weekend: boolean[];
  with_exoneration_boolean: boolean;
  with_image_boolean: boolean;
}

let ELEMENT_DATA: PeriodicElement[] = [];
const moment = _rollupMoment || _moment;

let DATE_DATA: SpecifyDateAdd[] = [];
