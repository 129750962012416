import { SpecialFormComponent } from './components/special-form/special-form.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {UsersComponent} from './components/users/users.component';
import {MaintenanceComponent} from './components/maintenance/maintenance-mall/maintenance.component';
import {PrincipalComponent} from './components/principal/principal.component';
import {AuthGuard} from './services/auth.guard';
import {NoFoundComponent} from './components/no-found/no-found.component';
import {MaintenanceMallComponentEvent} from './components/maintenance/maintenance-mall/event/maintenance-mall-event.component'
import {MaintenanceAdminComponentEvent} from './components/maintenance/maintenance-admin/event/maintenance-admin-event.component';
import {MaintenanceAdminComponentMall} from './components/maintenance/maintenance-admin/mall/maintenance-admin-mall.component';
import { AuditoriaComponent } from './components/auditoria/auditoria.component';
import { VisitorDetailComponent } from './components/visitant-detail/visitant-detail.component';
const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {
    path: '', component: PrincipalComponent, canActivate: [AuthGuard], children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent},
      {
        path: 'users', children: [
          {path: '', redirectTo: 'register', pathMatch: 'full'},
          {path: 'register', component: UsersComponent},
          {path: 'specials', component: SpecialFormComponent }
        ]
      },
      {
        path: 'event', children: [
          {path: '', redirectTo: 'mall', pathMatch: 'full'},
          {path: 'mall', component: MaintenanceMallComponentEvent},
          {path: 'admin', component: MaintenanceAdminComponentEvent},
        ]
      },
      {
        path: 'assign', children: [
          {path: '', redirectTo: 'event-mall', pathMatch: 'full'},
          {path: 'event-mall', component: MaintenanceComponent},
          {path: 'event-admin', component: MaintenanceAdminComponentMall},
        ]
      },
      {
        path: 'auditoria', component: AuditoriaComponent
      },
      {
        path: 'detalle/:DNI', component: VisitorDetailComponent
      },
    ]
  },
  {path: '**', component: NoFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule {
}
