export class ListMallDate {
  position : any;
  name: string;
  date_start : string;
  date_end: string;
  typeString: string;
  typeSimbol: string;
  color: string;
  date_weekend: boolean[];
  with_exoneration_boolean:  boolean;
  with_exoneration_msg: string;
  with_exoneration : string;
  with_image_boolean:  boolean;
  with_image_msg: string;
  with_image : string;
  id : number;
}
