export class Visitor {
  id: string;
  mall_id: string;
  event_id: string;
  gender: string;
  firstName: string;
  last_name: string;
  birthdate: any;
  email: string;
  document_type: string;
  nationality: string;
  cod_ubigeo: string;
  department: string;
  province: string;
  district: string;
  document: string;
  cellPhone: string;
  registerUserId: number;
  registerUserFullname: string;
  registerDatetime: Date;
  updateUserId: number;
  updateUserFullname: string;
  updateDatetime: Date;
  state: number;
  agree_terms: boolean;
  agree_exonerado : boolean;
  agree_image : boolean;
  additonal_data_est: boolean;
  additional_data: JSON;
  userSign: string;
}
