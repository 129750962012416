import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  data_user: any;

  constructor(private router: Router, private apiService: ApiService, private cookieService: CookieService ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    if (this.cookieService.get('access_token') === '' ) {
      localStorage.removeItem('data_user');
    }

    if (localStorage.getItem('data_user') != null) {
      const menu = ['/dashboard'];
      this.data_user = ApiService.getDataUser();
      this.apiService.getListMenu().subscribe((options:any) => {
//        if (this.data_user.mallId === 0) {
//          if (options.options.menu.length < 4) {
//            this.router.navigate(['/login']);
//          }
//        }
        options.options.menu.forEach(element => {
          if (element.child == null) {
            menu.push(element.route);
          } else {
            if (element.child.length === undefined) {
              // menu.push(element.child.route);
            } else {
              element.child.forEach(element => {
                menu.push(element.route);
              });
            }

          }
        });
      });
      let valid = 0;
      menu.forEach(element => {
        if (state.url == element) {
          valid = 1;
        }
      });
      if (valid == 1) {
        return true;
      } else {
        this.router.navigate(['/dashboard']);
        return false;
      }
    }
    this.router.navigate(['/login']);
    return false;
  }
}
