import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpParameterCodec} from '@angular/common/http';
import {Router} from '@angular/router';

import {ResponseAuth} from '../models/responseAuth';
import {Response} from '../models/response';
import {environment} from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

export class HttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string { return standardEncoding(k); }
  encodeValue(v: string): string { return standardEncoding(v); }
  decodeKey(k: string): string { return decodeURIComponent(k); }
  decodeValue(v: string) { return decodeURIComponent(v); }
}
function standardEncoding(v: string): string {
  return encodeURIComponent(v);
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  response = new Response();

  constructor(private http: HttpClient, private router: Router, private cookieService: CookieService) { }

  authenticate(userName, password):  Observable<ResponseAuth> {
    const data = new HttpParams({encoder: new HttpUrlEncodingCodec()})
        .set('grant_type', environment.auth_credentials.grant_type)
        .set('scope', environment.auth_credentials.scope)
        .set('client_id', environment.auth_credentials.client_id)
        .set('client_secret', environment.auth_credentials.client_secret)
        .set('username', userName)
        .set('password', password);

    const reqHeader = new HttpHeaders({
      'Authorization': 'Basic ' + btoa(`${environment.auth_credentials.client_id}:${environment.auth_credentials.client_secret}`),
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<ResponseAuth>(environment.auth_credentials.url, data, { headers: reqHeader });
  }

  checkProfileAccess(code_unique): Observable<any> {
    const data = '?user_c_iid=' + code_unique + '&sistema=' + environment.profile.code_system;
    const reqHeader = new HttpHeaders({
      'x-api-key': environment.profile.token,
    });
    return this.http.get<any>(environment.profile.url + data, { headers: reqHeader });
  }

  logout() {
    this.cookieService.delete('access_token');
    localStorage.removeItem('data_user');
    this.router.navigate(['/login']);
  }

  logoutRedirect() {
    this.cookieService.delete('access_token');
    localStorage.removeItem('data_user');
    window.location.href = '/';
  }
  getDataUser(): any {
    return JSON.parse(this.getItem('data_user'));
  }
  getItem(name: string ): any {
    return localStorage.getItem(name);
  }

}
