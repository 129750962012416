export class SpecifyDate {
  id: number;
  date_start:  string;
  date_end: string;
  with_exoneration: string;
  with_exoneration_boolean: boolean;
  with_exoneration_msg : string;
  with_image: string;
  with_image_boolean: boolean;
  with_image_msg : string;
  lunes:boolean;
  martes:boolean;
  miercoles:boolean;
  jueves:boolean;
  viernes: boolean;
  sabado: boolean;
  domingo: boolean;
  color: string;
  colorRow: string;
  ico_state: string;
  ico_state_msg : string;
  display : string;
}
