import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamical-form',
  templateUrl: './dynamical-form.component.html',
  styleUrls: ['./dynamical-form.component.styl']
})
export class DynamicalFormComponent implements OnInit {

  @Input() dynamicalGroup: FormGroup;
  @Input() jsonOfForm = [];

  constructor() { }

  ngOnInit() {
  }



}
